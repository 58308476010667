import merge from 'deepmerge';
import { UserConfig } from 'next-i18next';

let config: UserConfig & { clientOnly?: boolean } = {
  nonExplicitSupportedLngs: true,
  debug: false,
  i18n: {
    defaultLocale: 'fr',
    locales: ['fr', 'de'],
  },
  clientOnly: false,
};

export const setConfig = (
  customConfig: Partial<UserConfig & { clientOnly?: boolean }>
) => {
  config = merge(config, customConfig, {
    customMerge: (key) => {
      if (key === 'locales') {
        return (existing, incoming) => incoming;
      }
      return undefined;
    },
  });
};

export const getConfig = () => config;
