import { Link } from '@mui/material';
import { CrispService, RouteLink } from '@scorenco/core';
import type { marked } from 'marked';

import type { RendererFn } from '../type';

export const link: RendererFn<marked.Tokens.Link & { message?: string }> = (
  { href, tokens, message },
  { parse, key }
) => {
  if (href.startsWith('#crisp')) {
    return (
      <Link
        underline="always"
        href="#"
        key={key}
        onClick={() => {
          CrispService.show();

          if (message) {
            CrispService.sendMessageToUser(message);
          }
        }}
      >
        {parse(tokens)}
      </Link>
    );
  }

  return (
    <RouteLink href={href} key={key}>
      <Link component="a" underline="always">
        {parse(tokens)}
      </Link>
    </RouteLink>
  );
};
