import { buildGetInitialProps } from './buildGetInitialProps';
import { buildGetLayout } from './buildGetLayout';
import { buildGetServerSideProps } from './buildGetServerSideProps';
import { buildGetStaticPaths } from './buildGetStaticPaths';
import { buildGetStaticProps } from './buildGetStaticProps';

/**
 * Layout contenant les fonctionnalités minimal pour le bon fonctionnment d'une page
 */
export const BaseLayout = {
  buildGetInitialProps,
  buildGetLayout,
  buildGetServerSideProps,
  buildGetStaticPaths,
  buildGetStaticProps,
};
