import { Box } from '@mui/material';
import type { marked } from 'marked';

import type { RendererFn } from '../type';

export const list: RendererFn<marked.Tokens.List> = (
  { items, ordered },
  { key, parse, renderer }
) => {
  const children = items.map((item, index) => {
    return renderer.listItem(item, {
      parse,
      renderer,
      key: `listItem-${index}`,
    });
  });

  return (
    <Box key={key} width="100%" className="markdown-list">
      <Box
        display="inline-block"
        component={ordered ? 'ol' : 'ul'}
        textAlign="left"
      >
        {children}
      </Box>
    </Box>
  );
};
