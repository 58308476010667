import { memo } from 'react';

import { css } from '@emotion/react';
import { List, Box, Skeleton, ListItemButton } from '@mui/material';
import { EventItem } from '@scorenco/components';
import {
  useTranslation,
  Locale,
  Types,
  UsePaginatedQueryResult,
  RouteLink,
} from '@scorenco/core';
import { format } from 'date-fns';
import { fr, de } from 'date-fns/locale';

import { T_KEYS } from '@/translations';

import { useGroupedEvents } from './useGroupedEvents';

const LoadMoreButton = ({
  query,
}: {
  query: UsePaginatedQueryResult<any, any>;
}) => {
  if (!query.hasNext && query.isReadyToFetchMore) {
    return null;
  }

  return (
    <List>
      <ListItemButton
        onClick={() => {
          query.fetchMore();
        }}
        disabled={!query.isReadyToFetchMore || !query.hasNext}
      >
        <div style={{ textAlign: 'center', width: '100%' }}>Charger plus</div>
      </ListItemButton>
    </List>
  );
};

const GroupTitle = ({
  date,
  isToday,
}: {
  date?: string;
  isToday?: boolean;
}) => {
  const { t, locale } = useTranslation();

  return (
    <Box
      css={(theme) => css`
        text-align: left;
        padding: 0.5rem 1rem 0.5rem 1rem;
        text-transform: uppercase;
        font-size: ${isToday ? '1rem' : '0.8rem'};
        font-weight: bold;
        border-top: 1px solid ${theme.vars.palette.divider};
        border-bottom: 1px solid ${theme.vars.palette.divider};
        background-color: ${theme.vars.palette.background.default};
      `}
    >
      {date ? (
        isToday ? (
          t(T_KEYS.TODAY)
        ) : (
          format(new Date(date), 'EEEE d MMMM', {
            locale: locale === Locale.de_CH ? de : fr,
          })
        )
      ) : (
        <Skeleton width={150} />
      )}
    </Box>
  );
};

const InnerItem = memo(
  ({
    event,
    showCompetition,
    showShortName,
  }: {
    event?: Types.Competitions_Event_Detail;
    showShortName?: boolean;
    showCompetition?: boolean;
  }) => {
    if (event?.id === -1) {
      return null;
    }

    return (
      <RouteLink href={event?.url}>
        <EventItem
          showShortName={showShortName}
          css={css`
            margin: 0;
            padding: 0 1rem;
            cursor: pointer;
          `}
          event={event}
          showCompetition={showCompetition}
        />
      </RouteLink>
    );
  }
);

export const GroupedEvents = ({
  showShortName,
  query,
  showCompetition,
  competitionId,
}: {
  showShortName?: boolean;
  showCompetition?: boolean;
  query: UsePaginatedQueryResult<any, any>;
  autoScroll?: boolean;
  competitionId?: number;
}) => {
  const { t } = useTranslation();
  const groups = useGroupedEvents({
    query,
  });

  if (query?.count < 1) {
    return (
      <Box sx={{ background: 'white', textAlign: 'center', padding: '1rem 0' }}>
        {t(T_KEYS.NO_RESULT)}
      </Box>
    );
  }

  return (
    <>
      <List
        sx={{
          position: 'relative',
          '& ul': { padding: 0 },
          maxHeight: '30rem',
          overflowY: 'auto',
        }}
        subheader={<li />}
      >
        {groups.map((group, groupIndex) => {
          const isToday =
            group.title &&
            group.title === new Date().toISOString().slice(0, 10);
          const events = group.items.filter((event) => {
            return !competitionId || event?.competition_id === competitionId;
          });
          const content = (
            <ul>
              {!isToday && events?.length > 0 && (
                <GroupTitle date={group.title} isToday={isToday} />
              )}
              {events?.map((event, idx) => (
                <InnerItem
                  event={event}
                  key={`${groupIndex}-${idx}`}
                  showShortName={showShortName}
                  showCompetition={showCompetition}
                />
              ))}
            </ul>
          );

          return <li key={groupIndex}>{content}</li>;
        })}
        <LoadMoreButton query={query} />
      </List>
    </>
  );
};
