import { OverrideComponent, MuiComponents } from './types';

export const MuiButton: OverrideComponent = () => {
  const MuiButton: MuiComponents['MuiButton'] = {
    styleOverrides: {
      root: {
        paddingRight: '1.5rem',
        paddingLeft: '1.5rem',
        borderRadius: '999px',
        boxShadow: 'none',
        fontWeight: 700,
        margin: '0',
        width: 'max-content',

        '&:hover': {
          boxShadow: 'none',
        },

        '&:active': {
          boxShadow: 'none',
        },
      },
    },
  };

  return {
    MuiButton,
  };
};
