import { setLocale } from 'yup';

import { T_KEYS as KEYS } from './keys';

setLocale({
  mixed: {
    required: KEYS.FORM_ERROR_REQUIRED,
  },
  string: {
    email: KEYS.FORM_ERROR_INVALID_EMAIL,
  },
});

export const T_KEYS = KEYS;
