import type { marked } from 'marked';

import { Image } from '../../Image';
import type { RendererFn } from '../type';

export const image: RendererFn<marked.Tokens.Image> = (
  { href, text, title },
  { key }
) => {
  return <Image key={key} src={href} alt={text} title={title} />;
};
