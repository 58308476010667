/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@scorenco/core';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SportChip_GetSportInfoQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;

export type SportChip_GetSportInfoQuery = {
  __typename: 'query_root';
  clubs_sport?:
    | Array<{
        __typename: 'clubs_sport';
        id: number;
        emoji?: string | undefined;
        name?: string | undefined;
      }>
    | undefined;
};

export const SportChip_GetSportInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SportChip_GetSportInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      directives: [
        {
          kind: 'Directive',
          name: { kind: 'Name', value: 'cached' },
          arguments: [
            {
              kind: 'Argument',
              name: { kind: 'Name', value: 'ttl' },
              value: { kind: 'IntValue', value: '300' },
            },
          ],
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clubs_sport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emoji' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SportChip_GetSportInfoQuery,
  SportChip_GetSportInfoQueryVariables
>;
