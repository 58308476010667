import { OverrideComponent, MuiComponents } from './types';

export const MuiTableBody: OverrideComponent = (theme) => {
  const MuiTableBody: MuiComponents['MuiTableBody'] = {
    styleOverrides: {
      root: {
        borderTop: `1px solid ${theme.vars.palette.divider}`,
        borderBottom: `1px solid ${theme.vars.palette.divider}`,

        '&:first-of-type': {
          borderTop: 0,
        },
        '&:last-child': {
          borderBottom: 0,
        },
      },
    },
  };

  return {
    MuiTableBody,
  };
};
