import { ReactNode } from 'react';

import { NextNavigationService, NextAmplifyService } from '../../services';
import { GetLayout } from '../../types';
import { BaseLayout } from '../BaseLayout';

import { DefaultLayoutComponent } from './DefaultLayoutComponent';
import { PageContext } from './PageContext';
import { DefaultLayoutOptions } from './types';

const defaultLayout = (page?: ReactNode) => {
  return <DefaultLayoutComponent>{page}</DefaultLayoutComponent>;
};

const pageContextLayout: GetLayout = (page, appProps) => {
  return <PageContext.Provider value={appProps}>{page}</PageContext.Provider>;
};

export const buildGetLayout = (
  layoutProps?: DefaultLayoutOptions,
  getLayouts?: GetLayout[]
) => {
  return BaseLayout.buildGetLayout([
    pageContextLayout,
    NextAmplifyService.getLayoutFn(layoutProps?.amplify),
    NextNavigationService.getLayoutFn(layoutProps?.navigation),
    defaultLayout,
    ...(getLayouts || []),
  ]);
};
