import merge from 'deepmerge';
import type { GetStaticProps, GetStaticPropsResult } from 'next';

import { GetStaticPropsWithPrevious } from '../../types';

import { isNextProps } from './utils';

export type BuildGetStaticProps<P extends { [key: string]: any } = any> = (
  getStaticPropsArray?: GetStaticPropsWithPrevious<P>[]
) => GetStaticProps<P>;

/**
 * Permet de construire la fonction GetStaticProps pour une page.
 *
 * Cette fonction est appelé au moment du build afin de récupérérer les données
 * et construire la page si possible.
 */
export const buildGetStaticProps: BuildGetStaticProps =
  (getStaticPropsArray = []) =>
  async (context) =>
    await getStaticPropsArray.reduce(
      async (promisedAcc, getStaticProps) => {
        const acc = await promisedAcc;

        if (isNextProps(acc)) {
          return acc;
        }

        const result = await getStaticProps(context, acc as { props: any });

        if (isNextProps(result)) {
          return result;
        }

        return merge(acc, result);
      },
      Promise.resolve({
        props: {},
      } as GetStaticPropsResult<any>)
    );
