import { ElementType } from 'react';

import { useQuery } from '@apollo/client';
import { Stack, StackProps, Typography } from '@mui/material';
import { RouteLink } from '@scorenco/core';

import { RouteKey } from '../../routes';

import { SportChip_GetSportInfoDocument } from './SportChip.query.generated';

type SportChipProps = StackProps & { sport?: string; component?: ElementType };

export const SportChip = ({
  sport,
  component = 'div',
  sx,
  ...rest
}: SportChipProps) => {
  const sportQuery = useQuery(SportChip_GetSportInfoDocument, {
    variables: { slug: sport as string },
    skip: !sport,
    context: {
      role: 'anonymous',
    },
  });
  const sportInfo = sportQuery.data?.clubs_sport?.[0];

  return (
    <RouteLink
      href={
        sport && {
          routeKey: RouteKey.Sport,
          params: { sport },
        }
      }
    >
      <Stack
        component={sport ? 'a' : component}
        sx={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          textTransform: 'uppercase',
          fontWeight: 'normal',
          borderRadius: 9999,
          backgroundColor: sport ? 'premium.main' : 'default.main',
          flexDirection: 'row',
          gap: 1,
          textDecoration: 'none',
          color: 'premium.contrastText',
          px: '0.75rem',
          py: '0.125rem',
          width: 'auto',
          ...sx,
        }}
        {...rest}
      >
        {sportInfo?.name && (
          <Typography component="span" fontSize="small">
            {sportInfo?.name}
          </Typography>
        )}
        {sportInfo?.emoji && (
          <Typography component="span" fontSize="1rem">
            {sportInfo?.emoji}
          </Typography>
        )}
      </Stack>
    </RouteLink>
  );
};
