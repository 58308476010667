import { getLayoutFn } from './getLayoutFn';
import { init } from './init';

export * from './types';

export const NextAmplifyService = {
  getLayoutFn,
  init,
};

export * from './ssrIsAuthenticated';
