import { I18nService } from '@scorenco/core';
import { useTranslation, UserConfig } from 'next-i18next';

import { setConfig } from './config';

export const init = (customConfig: UserConfig & { clientOnly?: boolean }) => {
  setConfig(customConfig);

  I18nService.init({ useTranslation });
};
