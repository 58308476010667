import { Typography } from '@mui/material';
import type { marked } from 'marked';

import { RendererFn } from '../type';

export const blockquote: RendererFn<marked.Tokens.Blockquote> = (
  { tokens },
  { key, parse }
) => {
  return (
    <Typography key={key} marginX="auto" marginY={2} maxWidth="sm">
      {parse(tokens)}
    </Typography>
  );
};
