import { Box } from '@mui/material';
import type { marked } from 'marked';

import type { RendererFn } from '../type';

import { unescape } from './utils';

export const codespan: RendererFn<
  marked.Tokens.Codespan | marked.Tokens.Code
> = (token, { key }) => {
  const lang = (token.type === 'code' && token.lang) || undefined;
  return (
    <Box component="code" key={key} className={lang}>
      {unescape(token.text)}
    </Box>
  );
};
