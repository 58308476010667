import { envVars } from '../../helpers';

export const REST_ENDPOINT = envVars(
  'NEXT_PUBLIC_REST_ENDPOINT',
  process.env.NEXT_PUBLIC_REST_ENDPOINT
)
  .required()
  .asString();

export const GRAPHQL_API_ENDPOINT = envVars(
  'NEXT_PUBLIC_GRAPHQL_API_ENDPOINT',
  process.env.NEXT_PUBLIC_GRAPHQL_API_ENDPOINT
)
  .required()
  .asString();
