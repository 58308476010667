import type { ColorSystemOptions } from '@mui/material';

import { COLORS } from '../../constants';

import { createColorScheme } from './utils';

export const greenScheme: ColorSystemOptions = createColorScheme({
  palette: {
    background: {
      default: COLORS.GREEN_30,
      paper: COLORS.GREEN_20,
    },
    text: {
      primary: COLORS.GREEN_100,
    },
    primary: {
      dark: COLORS.BLUE_70,
      main: COLORS.BLUE_99,
      light: COLORS.BLUE_99,
      contrastText: COLORS.BLUE_40,
    },
    logo: {
      score_co: COLORS.GREEN_100,
      gradient: [COLORS.BLUE_50, COLORS.BLUE_50],
    },
  },
});
