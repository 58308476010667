const sessionStorage: Record<string, string | undefined> = {};

export const getItemFromStorage = (
  key: string,
  options: { once?: boolean } = {}
) => {
  let value: string | undefined = undefined;

  try {
    value = localStorage.getItem(key) || undefined;
  } catch (err) {
    value = sessionStorage[key];
  }

  if (options.once) {
    removeItemFromStorage(key);
  }

  return value;
};

export const setItemToStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {
    sessionStorage[key] = value;
  }
};

export const removeItemFromStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    delete sessionStorage[key];
  }
};
