import merge from 'deepmerge';
import type { GetServerSideProps, GetServerSidePropsResult } from 'next';

import { GetServerSidePropsWithPrevious } from '../../types';

import { isNextProps } from './utils';

export type BuildGetServerSideProps<P extends { [key: string]: any } = any> = (
  getServerSidePropsArray?: GetServerSidePropsWithPrevious<P>[]
) => GetServerSideProps<P>;

/**
 * Permet de construire la fonction GetStaticProps pour une page.
 *
 * Cette fonction est appelé au moment du build afin de récupérérer les données
 * et construire la page si possible.
 */
export const buildGetServerSideProps: BuildGetServerSideProps = (
  getServerSidePropsArray = []
) => {
  return async (context) => {
    const res = await getServerSidePropsArray.reduce(
      async (promisedAcc, getServerSideProps) => {
        const acc = await promisedAcc;

        if (isNextProps(acc)) {
          return acc;
        }

        const result = await getServerSideProps(context, acc as { props: any });

        if (isNextProps(result)) {
          return result;
        }

        // Les props strapi (menu et footer) se retrouvent en doublon dans les props sans ça
        const accWithoutStrapiProps = {
          ...acc,
          props: { ...acc.props, menu: null, footer: null },
        };

        return merge(
          accWithoutStrapiProps as { props: any },
          result as { props: any }
        );
      },
      Promise.resolve({
        props: {},
      } as GetServerSidePropsResult<any>)
    );

    if (isNextProps(res)) {
      return res;
    }

    return { props: (res as { props: any }).props };
  };
};
