import { css } from '@emotion/react';
import { Stack, Typography, Link, Box } from '@mui/material';
import { CMPService, RouteLink } from '@scorenco/core';
import { usePageContext } from '@scorenco/next';

import { WebLayoutPageProps } from '../../layouts';

export const FooterMenu = () => {
  const pageContext = usePageContext<WebLayoutPageProps>();

  const footer = pageContext.pageProps.footer;

  if (!footer?.id) {
    return null;
  }

  return (
    <Box
      css={(theme) => css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        ${theme.breakpoints.up('md')} {
          flex-wrap: nowrap;
        }
      `}
    >
      {footer?.items.map((mainItem) => (
        <Stack
          key={mainItem.id}
          direction="column"
          spacing={1}
          css={(theme) => css`
            min-width: 300px;

            ${theme.breakpoints.up('md')} {
              min-width: auto;
            }
          `}
        >
          <Typography variant="h5" color="secondary.main" mt={2}>
            {mainItem.attributes?.title}
          </Typography>

          {mainItem.items?.map((child) => {
            const url = child.attributes?.url;

            if (!url) {
              return null;
            }

            if (url === '#rgpd') {
              return (
                <Link
                  component="span"
                  key={url}
                  underline="hover"
                  variant="body2"
                  onClick={CMPService.show}
                  role="button"
                >
                  {child.attributes?.title}
                </Link>
              );
            }

            return (
              <RouteLink key={url} href={url}>
                <Link underline="hover" variant="body2">
                  {child.attributes?.title}
                </Link>
              </RouteLink>
            );
          })}
        </Stack>
      ))}
    </Box>
  );
};
