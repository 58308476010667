import { useTheme } from '@emotion/react';
import { PaletteColor } from '@mui/material';
import { Box, BoxProps } from '@mui/system';

export type ShieldColorType = 'away' | 'home';

export type ShieldProps = BoxProps & {
  color?: ShieldColorType;
};

export const Shield = ({
  component = 'svg',
  color = 'home',
  ...rest
}: ShieldProps) => {
  const theme = useTheme();
  const paletteColor: PaletteColor = theme.vars.palette[
    color as keyof typeof theme.vars.palette
  ] as unknown as PaletteColor;

  return (
    <Box component={component} viewBox="0 0 130 160" {...rest}>
      <g fill="none" fillRule="evenodd">
        <path
          fill={paletteColor.main}
          d="M12.38 7.18S0 35.18 0 63.16c0 62.22 53.53 91.57 63.44 96.47.99.5 2.14.5 3.13 0C76.48 154.8 130 125.7 130 63.17c0-32.65-12.38-55.98-12.38-55.98S99.5 0 65.1 0C30.88 0 12.38 7.18 12.38 7.18"
        />
        <path
          fill={paletteColor.dark}
          d="M0 63.16c0 62.22 53.53 91.57 63.44 96.47A3.6 3.6 0 0 0 65 160V0C30.84.01 12.38 7.18 12.38 7.18S0 35.18 0 63.16z"
        />
      </g>
    </Box>
  );
};
