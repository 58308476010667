import { BaseLayout } from '../BaseLayout';

import { appEnhancer } from './appEnhancer';
import { buildGetInitialProps } from './buildGetInitialProps';
import { buildGetLayout } from './buildGetLayout';
import { buildGetServerSideProps } from './buildGetServerSideProps';
import { buildGetStaticProps } from './buildGetStaticProps';
import { init } from './init';

export * from './useCognitoCallbackPage';
export * from './useConnectPage';
export * from './types';
export * from './PageContext';

/**
 * Layout préconfiguré avec les services suivant: i18n, navigation, authentification, routage
 */
export const DefaultLayout = {
  ...BaseLayout,
  appEnhancer,
  buildGetInitialProps,
  buildGetServerSideProps,
  buildGetStaticProps,
  buildGetLayout,
  init,
};
