import { useMemo } from 'react';

import NextScript from 'next/script';

type JsonLdProps = { value: Record<string, any> };

type CompactObjectFn = <T = any>(value: T) => Partial<T>;

const compactObject: CompactObjectFn = (value) => {
  if (Array.isArray(value)) {
    return value.map(compactObject);
  }

  if (typeof value !== 'object' || value === null) {
    return value;
  }

  const obj = Object.fromEntries(
    Object.entries(value)
      .map(([_, v]) => [_, compactObject(v)])
      .filter(([, v]) => v !== null && v !== undefined && v !== '')
  );

  return Object.keys(obj).length === 0 ? undefined : obj;
};

export const JsonLd = ({ value }: JsonLdProps) => {
  const normalizedValue = useMemo(() => {
    const v = compactObject(value);
    return v && JSON.stringify(v);
  }, [value]);

  if (!normalizedValue) {
    return null;
  }

  return (
    <NextScript
      type="application/ld+json"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: normalizedValue,
      }}
    />
  );
};
