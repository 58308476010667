import { PropsWithChildren } from 'react';

import { useTheme } from '@emotion/react';
import { ExitToApp, PestControl } from '@mui/icons-material';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { NextRouter, useRouter } from 'next/router';

type DebugModeProps = PropsWithChildren<{
  preview?: boolean;
}>;

const exitPreviewMode = async (router: NextRouter) => {
  const response = await fetch('/api/exit-preview');
  if (response) {
    router.reload();
  }
};

/**
 * Menu qui affiche des liens utiles pour le contributeur ou developpeur
 */
export const DebugMode = ({ preview, children }: DebugModeProps) => {
  const router = useRouter();
  const theme = useTheme();

  if (!preview) {
    return null;
  }

  return (
    <SpeedDial
      ariaLabel="Menu de preview"
      sx={{
        position: 'fixed',
        bottom: 20,
        left: 24,
        '.MuiSpeedDial-fab': {
          background: theme.vars.palette.warning.main,
          color: theme.vars.palette.warning.contrastText,

          ':hover': {
            background: theme.vars.palette.warning.dark,
          },
        },
      }}
      icon={<SpeedDialIcon icon={<PestControl />} />}
    >
      {children}

      <SpeedDialAction
        icon={<ExitToApp />}
        tooltipTitle="Quitter la preview"
        onClick={() => exitPreviewMode(router)}
      />
    </SpeedDial>
  );
};
