import { orderIncomingTeams } from '../utils';

import { FragmentType } from './types';

export const competitions_event_detail: FragmentType<'competitions_event_detail'> =
  {
    fields: {
      teams: orderIncomingTeams(),
      playing_teams: orderIncomingTeams(),
    },
  };
