import { Amplify, ResourcesConfig } from 'aws-amplify';
import {
  confirmResetPassword,
  confirmSignUp,
  fetchAuthSession,
  resendSignUpCode,
  resetPassword,
  signIn,
  signInWithRedirect,
  signOut,
  signUp,
  updateUserAttribute,
  getCurrentUser,
  confirmUserAttribute,
} from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import merge from 'deepmerge';

import {
  AUTH_COOKIE_DOMAIN,
  // AUTH_COOKIE_DOMAIN,
  IDENTITY_POOL_ID,
  IDP_DOMAIN,
  REDIRECT_SIGN_IN,
  REDIRECT_SIGN_OUT,
  USER_POOL_CLIENT_ID,
  USER_POOL_ID,
  // USER_POOL_REGION,
} from '../../env-vars';
import { AuthClient } from '../types';

class EncodedCookieStorage extends CookieStorage {
  constructor(data: ConstructorParameters<typeof CookieStorage>[0]) {
    super(data);
  }

  async setItem(key: string, value: string) {
    if (!key.endsWith('LastAuthUser') && !key.endsWith('signInDetails')) {
      await super.setItem(key, value); // compressToUTF16(value));
    }
  }

  async getItem(key: string) {
    const item = await super.getItem(key);
    return item ? item : null; //?? decompressFromUTF16(item);
  }
}

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: IDENTITY_POOL_ID.get(),
      userPoolClientId: USER_POOL_CLIENT_ID.get(),
      userPoolId: USER_POOL_ID.get(),
      loginWith: {
        oauth: {
          domain: IDP_DOMAIN.get(),
          redirectSignIn: [REDIRECT_SIGN_IN.get()],
          redirectSignOut: [REDIRECT_SIGN_OUT.get()],
          responseType: 'code',
          scopes: ['email', 'profile', 'openid'],
        },
      },
    },
  },
};

export const initAuthClient = ({
  ssr = false,
  resourcesConfig = {},
}: {
  ssr?: boolean;
  resourcesConfig?: ResourcesConfig;
} = {}) => {
  Amplify.configure(merge(amplifyConfig, resourcesConfig), {
    ssr,
  });

  if (ssr) {
    cognitoUserPoolsTokenProvider.setKeyValueStorage(
      new EncodedCookieStorage({
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: AUTH_COOKIE_DOMAIN.get(),
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: false,
      })
    );
  }
};

export const createAuthClient = async (): Promise<AuthClient> => {
  return {
    confirmResetPassword,
    confirmSignUp,
    fetchAuthSession,
    resendSignUpCode,
    resetPassword,
    signIn,
    signInWithRedirect,
    signOut,
    signUp,
    updateUserAttribute,
    confirmUserAttribute,
    getCurrentUser,
  };
};
