import { OverrideComponent, MuiComponents } from './types';

export const MuiPaper: OverrideComponent = (theme) => {
  const MuiPaper: MuiComponents['MuiPaper'] = {
    variants: [
      {
        props: { variant: 'bordered' },
        style: {
          border: `1px solid ${theme.vars.palette.divider}`,
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
    ],
  };

  return {
    MuiPaper,
  };
};
