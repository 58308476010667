import { HrefProp, useRouterLinkResolver } from '@scorenco/core';
import Head from 'next/head';

type PageSeoProps = {
  title: string;
  description: string;
  url?: HrefProp;
  sharedImage?: string;
};

export const PageSeo = ({
  title,
  description,
  url,
  sharedImage,
}: PageSeoProps) => {
  const { routerLinkResolver } = useRouterLinkResolver();

  const canonicalUrl = routerLinkResolver({
    href: url,
    absoluteUrl: true,
  });
  const sharedImageUrl =
    sharedImage ||
    `${process.env.NEXT_PUBLIC_WEB_URL}/images/default-shared-image.png`;

  return (
    <Head>
      {/* Default metadata */}
      <title>{title}</title>
      {/* <meta name="robots" content="index,follow" key="robots" /> */}
      <meta name="title" content={title} key="title" />
      <meta name="description" content={description} key="description" />
      {canonicalUrl && (
        <link rel="canonical" href={canonicalUrl.href} key="rel-canonical" />
      )}

      {/* Twitter metadata */}
      <meta name="twitter:title" content={title} key="twitter:title" />
      <meta
        name="twitter:description"
        content={description}
        key="twitter:description"
      />
      <meta
        name="twitter:image:src"
        content={sharedImageUrl}
        key="twitter:image:src"
      />
      {canonicalUrl && (
        <meta
          name="twitter:url"
          content={canonicalUrl.href}
          key="twitter:url"
        />
      )}

      {/* OG metadata */}
      <meta property="og:title" content={title} key="og:title" />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta property="og:image" content={sharedImageUrl} key="og:image" />
      {canonicalUrl && (
        <meta property="og:url" content={canonicalUrl.href} key="og:url" />
      )}

      {/* Application metadata */}
      {/*
        <meta property="al:android:url" content="medium://p/2b6c0c5b5e27" key="al:android:url" />
        <meta property="al:ios:url" content="medium://p/2b6c0c5b5e27" key="al:ios:url" />
      */}

      <meta
        name="google-site-verification"
        content="UohjolqyEbYHlBDsZdhZ-oue7blG5ByQ4EOT042ynng"
      />
    </Head>
  );
};
