import { NavigateNext } from '@mui/icons-material';
import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  Link,
  Typography,
} from '@mui/material';
import { HrefProp, RouteLink, useRouterLinkResolver } from '@scorenco/core';

import { RouteKey } from '../../routes';
import { JsonLd } from '../JsonLd';

export type BreadcrumbsProps = MuiBreadcrumbsProps & {
  links: {
    label: string;
    href?: HrefProp;
  }[];
};

export const Breadcrumbs = ({ links, ...rest }: BreadcrumbsProps) => {
  const { routerLinkResolver } = useRouterLinkResolver();

  return (
    <>
      <JsonLd
        value={{
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: links
            .filter(Boolean)
            .map(
              ({ label, href }, index) =>
                label &&
                href && {
                  '@type': 'ListItem',
                  position: index + 1,
                  item: {
                    '@id': routerLinkResolver({ href, absoluteUrl: true })
                      ?.href,
                    name: label,
                  },
                }
            )
            .filter(Boolean),
        }}
      />

      <MuiBreadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
        {...rest}
      >
        <RouteLink href={{ routeKey: RouteKey.Home }}>
          <Link key="home" underline="hover" color="inherit">
            Accueil
          </Link>
        </RouteLink>

        {links.filter(Boolean).map(({ label, href }) => {
          if (!label || label === '') {
            return null;
          }
          return href ? (
            <RouteLink key={label} href={href}>
              <Link underline="hover" color="inherit">
                {label}
              </Link>
            </RouteLink>
          ) : (
            <Typography key={label}>{label}</Typography>
          );
        })}
      </MuiBreadcrumbs>
    </>
  );
};
