import { competitions_event } from './competitions_event';
import { competitions_event_detail } from './competitions_event_detail';
import { lives_action } from './lives_action';
import { lives_live } from './lives_live';
import { lives_post } from './lives_post';
import { teams_team_in_season } from './teams_team_in_season';

export const fragments = {
  competitions_event,
  competitions_event_detail,
  lives_action,
  lives_live,
  lives_post,
  teams_team_in_season,
};
