import { Typography } from '@mui/material';
import type { marked } from 'marked';

import type { RendererFn } from '../type';

export const paragraph: RendererFn<marked.Tokens.Paragraph> = (
  { tokens },
  { key, parse }
) => {
  return (
    <Typography key={key} marginX="auto" marginY={2} width="100%">
      {parse(tokens)}
    </Typography>
  );
};
