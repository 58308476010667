import { Types } from '../../types';

import { CRISP_WEBSITE_ID } from './env-vars';

/**
 * La documentation CRISP est disponible ici : https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/
 */

const getClient = (): any[] | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  if (!window.$crisp) {
    window.$crisp = [];
  }

  return window.$crisp;
};

export const init = () => {
  // Disable warnings
  getClient()?.push(['safe', true]);
};

/**
 * Ouvre le chat
 */
export const show = () => {
  getClient()?.push(['do', 'chat:open']);
  getClient()?.push(['do', 'chat:show']);
};

/**
 * Ferme le chat
 */
export const hide = () => {
  getClient()?.push(['do', 'chat:hide']);
};

/**
 * Ajoute des segments utilisateur à la conversation
 */
export const addSegment = (segments: string[], overwrite = false) => {
  getClient()?.push(['set', 'session:segments', [segments], overwrite]);
};

/**
 * Envoi un message de la part du support
 */
export const sendMessageToUser = (message: string) => {
  getClient()?.push(['do', 'message:show', ['text', message]]);
};

/**
 * Envoi un message de la part de l'utilisateur au support
 */
export const sendMessageFromUser = (message: string) => {
  getClient()?.push(['do', 'message:send', ['text', message]]);
};

/**
 * Ajoute les infos utilisateurs à la conversation lorsqu'il est connecté.
 */
export const setUserInfo = (user?: Types.Users_User) => {
  if (!user) {
    getClient()?.push(['do', 'session:reset']);
  } else {
    getClient()?.push([
      'set',
      'user:firstname',
      [user.first_name, user.last_name].filter(Boolean).join(' '),
    ]);
    getClient()?.push(['set', 'user:email', user.email]);
    getClient()?.push(['set', 'user:avatar', user.picture_url]);
  }
};

/**
 * Récupérer l'url de l'iframe de chat
 */
export const getChatIframeUrl = (user?: Types.Users_User) => {
  return `https://go.crisp.chat/chat/embed/?${[
    `website_id=${CRISP_WEBSITE_ID.get()}`,
    user?.email && `user_email=${encodeURIComponent(user.email)}`,
    user?.username && `user_nickname=${encodeURIComponent(user.username)}`,
    user?.picture_url && `user_avatar=${encodeURIComponent(user.picture_url)}`,
  ]
    .filter(Boolean)
    .join('&')}
`;
};
