import {
  NextAmplifyService,
  NextI18nService,
  NextRouterService,
} from '../../services';

export const init = (options: {
  amplify: Parameters<typeof NextAmplifyService.init>[0];
  i18n: Parameters<typeof NextI18nService.init>[0];
  router: Parameters<typeof NextRouterService.init>[0];
}) => {
  NextI18nService.init(options.i18n);
  NextRouterService.init(options.router);
  NextAmplifyService.init(options.amplify);
};
