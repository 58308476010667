import merge from 'deepmerge';

import {
  GetInitialProps,
  GetInitialPropsResult,
  GetInitialPropsWithPrevious,
} from '../../types';

import { isNextProps } from './utils';

type BuildGetInitialProps = (
  getInitialPropsArray?: (GetInitialPropsWithPrevious | undefined)[]
) => GetInitialProps;

/**
 * Permet de construire la fonction GetInitialProps pour une page.
 *
 * Cette fonction est appelé côté client et côté serveur. Attention,
 * elle est dépréciée.
 */
export const buildGetInitialProps: BuildGetInitialProps =
  (getInitialPropsArray = []) =>
  async (context) =>
    await getInitialPropsArray.reduce(async (promisedAcc, getInitialProps) => {
      const acc = await promisedAcc;

      if (!getInitialProps || isNextProps(acc)) {
        return acc;
      }

      const result = await getInitialProps(context, acc);

      if (isNextProps(result)) {
        return result;
      }

      return merge(acc, result);
    }, Promise.resolve({} as GetInitialPropsResult));
