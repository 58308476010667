import Head from 'next/head';

export const WebMeta = ({
  title,
  description,
  image,
  url,
}: {
  title: string;
  description: string;
  image?: string;
  url?: string;
}) => {
  return (
    <Head>
      <title>{title}</title>
      {url && <link rel="canonical" href={url} />}
      <meta name="description" content={description} />
      {/* OG */}
      {url && <meta property="og:url" content={url} />}
      <meta property="og:image" content={image} />
      <meta name="og:image:width" content="1200" />
      <meta name="og:image:height" content="630" />
      <meta property="og:title" content={title} key="og:title" />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      {/* Twitter */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:creator" content="@Score_n_co" />
    </Head>
  );
};
