import { css } from '@emotion/react';
import { Box, BoxProps } from '@mui/system';

import { ScorencoLogo } from '../../illustrations';

const style = css`
  --toolbar-height: 2.5rem;

  display: flex;
  align-items: center;

  .appbar-logo_svg {
    width: auto;
    height: var(--toolbar-height);
    margin: 0.5rem 0;
  }
`;

export type AppBarLogoProps = BoxProps;

export const AppBarLogo = (props: AppBarLogoProps) => (
  <Box css={style} {...props}>
    <ScorencoLogo className="appbar-logo_svg" />
  </Box>
);
