import { InvalidationPolicyCache } from '@nerdwallet/apollo-cache-policies';

import { fragments } from './fragments';
import { introspection } from './generated';
import { queries } from './queries';

let cache: InvalidationPolicyCache;

export const getCache = (forceRecreate = false) => {
  if (!cache || forceRecreate) {
    cache = new InvalidationPolicyCache({
      typePolicies: {
        Query: {
          fields: { ...queries },
        },
        ...fragments,
      },
      possibleTypes: introspection.possibleTypes,
      enableCollections: true,
      invalidationPolicies: {
        // Un objet est retiré du cache s'il n'y a pas eu
        // de modification de cet objet pendant 1 heure.
        timeToLive: 1200 * 60 * 60 * 1000,
        types: {
          apps_version: {
            timeToLive: 5 * 60 * 1000,
          },
        },
      },
    });
  }

  return cache;
};
