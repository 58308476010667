export enum Locale {
  fr_FR = 'fr-FR',
  de_CH = 'de-CH',
}

export const toLocale = (locale?: string) => {
  if (locale?.startsWith('de')) {
    return Locale.de_CH;
  }

  return Locale.fr_FR;
};
