import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { getRefreshToken } from '../../amplify/getRefreshToken';

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        switch (extensions?.code) {
          case 'invalid-jwt': {
            const oldHeaders = operation.getContext().headers;

            const refreshTokenAndRetry = async () => {
              const token = await getRefreshToken();
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: token,
                },
              });
              return forward(operation);
            };

            return fromPromise(refreshTokenAndRetry());
          }
          default:
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
        }

        return undefined;
      });
    }

    if (networkError) {
      console.log('[Network error]: ', networkError);
    }
  }
);
