import { css } from '@emotion/react';
import { Typography, Theme } from '@mui/material';
import { Box } from '@mui/system';
import { Image } from '@scorenco/components';

const style = {
  root: (theme: Theme) => css`
    background: ${theme.vars.palette.secondary.main};
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding: 2rem 2rem;
    cursor: pointer;

    .fan-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 15%;

      transition: 1s;
      line-height: 1rem;

      img {
        height: 3.25rem;
      }
    }

    .fan-text {
      width: 60%;

      line-height: 1.25rem;

      h4 {
        line-height: 1.75rem;
      }
    }

    img.shape-images {
      width: 18rem;
    }
  `,
};

export const JoinClub = ({ ...rest }) => {
  // TODO: Récupérer le fan lorsque le service sera disponible

  return (
    <Box css={style.root} className="ad-scorenco" {...rest}>
      <Box className="fan-text">
        <Typography
          variant="h4"
          sx={{ color: 'white', fontSize: '0.3rem', mb: '0.2rem' }}
        >
          Bénévole de ce club ?
        </Typography>

        <Typography sx={{ color: 'white' }}>
          Activez l'espace de gestion gratuitement pour votre club !
        </Typography>
      </Box>

      <Image
        src="/images/shape-join-scorenco.png"
        style={{ position: 'absolute', right: '-3rem' }}
        className="shape-images"
      />
    </Box>
  );
};
