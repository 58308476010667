import { appWithTheme } from '@scorenco/components';
import {
  ApolloService,
  SentryService,
  CrispService,
  ApolloServiceProps,
  CMPService,
} from '@scorenco/core';
import {
  AppLayout,
  AppPropsWithLayout,
  DefaultLayout,
  NextGoogleAnalyticsService,
} from '@scorenco/next';

import { ErrorComponent, GlobalSeo } from '../components';
import { routerConfig } from '../routes';

DefaultLayout.init({
  i18n: {
    i18n: {
      defaultLocale: 'fr',
      locales: ['fr' /*'de'*/],
    },
    fallbackLng: ['fr' /*'de'*/],
  },
  router: {
    baseUrl: process.env.NEXT_PUBLIC_WEB_URL,
    config: routerConfig,
  },
  amplify: {},
});
CrispService.init();

const App = (appProps: AppPropsWithLayout<ApolloServiceProps>) => {
  const { pageProps } = appProps;

  // On ne veut pas de Crisp et de la validation des cookies sur l'overlay d'un live et la couleur du html et body en transparent
  if (
    typeof window !== 'undefined' &&
    window.location.pathname.includes('overlay')
  ) {
    return (
      <SentryService Fallback={ErrorComponent}>
        <ApolloService initialApolloState={pageProps.initialApolloState}>
          <AppLayout {...appProps} />
        </ApolloService>
      </SentryService>
    );
  }

  return (
    <SentryService Fallback={ErrorComponent}>
      <CrispService>
        <ApolloService initialApolloState={pageProps.initialApolloState}>
          <CMPService>
            <NextGoogleAnalyticsService>
              <GlobalSeo />
              <AppLayout {...appProps} />
            </NextGoogleAnalyticsService>
          </CMPService>
        </ApolloService>
      </CrispService>
    </SentryService>
  );
};

export default appWithTheme(DefaultLayout.appEnhancer(App as any));
