import { GetLayout } from '../../types';

export type BuildGetLayout<P = any> = (
  getLayouts?: GetLayout<P>[]
) => GetLayout<P>;

/**
 * Permet de construire le layout de la page. Si on navigue entre deux pages avec le même
 * layout, une partie du rendu de la page est conservée.
 */
export const buildGetLayout: BuildGetLayout = (getLayouts = []) => {
  const invGetLayouts = getLayouts.reverse();

  return (page, appProps) =>
    invGetLayouts.reduce((acc, getLayout) => getLayout(acc, appProps), page);
};
