export const Sports = {
  Basket: 'basket' as const,
  Football: 'football' as const,
  Hand: 'hand' as const,
  IceHockey: 'hockey-sur-glace' as const,
  RinkHockey: 'rink-hockey' as const,
  RollerHockey: 'roller-hockey' as const,
  Rugby: 'rugby' as const,
  Volley: 'volley' as const,
};

/**
 * Liste des sports disponibles classés par importance
 */
export const ALL_SPORTS = [
  Sports.Football,
  Sports.Basket,
  Sports.Rugby,
  Sports.Hand,
  Sports.Volley,
  Sports.IceHockey,
  Sports.RinkHockey,
  Sports.RollerHockey,
];
