import { cloneElement, isValidElement, useMemo } from 'react';

import { RouteLinkProps } from '@scorenco/core';
import NextLink from 'next/link';

type NextRouteLinkProps = Omit<RouteLinkProps, 'href'> & {
  href: string;
};

export const RouteLink = ({
  children,
  passHref = true,
  locale,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  routerDirection,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  routerAction,
  href,
  ...rest
}: NextRouteLinkProps) => {
  const nextLocale = useMemo(() => locale && locale.split('-')[0], [locale]);

  const extendedChildren = useMemo(() => {
    if (!isValidElement(children)) {
      throw new Error('children of a RouteLink must be a ReactElement');
    }

    return cloneElement(children, {
      target:
        href?.startsWith('http') &&
        !href.includes(process.env.NEXT_PUBLIC_OLD_WEB_URL as string)
          ? '_blank'
          : '_self',
    } as any);
  }, [children, href]);

  return (
    <NextLink
      legacyBehavior
      passHref={passHref}
      locale={nextLocale}
      prefetch={false}
      href={href}
      {...rest}
    >
      {extendedChildren}
    </NextLink>
  );
};
