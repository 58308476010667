import { GetStaticProps } from 'next';

import { translationsLoader } from './translationsLoader';
import { NextI18nServiceOptions } from './types';

export const getStaticPropsFn = (
  options?: NextI18nServiceOptions
): GetStaticProps => {
  const loadTranslations = translationsLoader();

  return async ({ locale }) => {
    // Récupération des traductions côté serveur
    const i18nProps = await loadTranslations({
      locale: locale || options?.defaultLocale,
      namespaces: options?.namespaces,
    });

    return {
      props: i18nProps,
    };
  };
};
