import { createAuthClient } from './factories/amplify-auth-client';
import { authClientMock } from './factories/mock-auth-client';
import { AuthClient } from './types';

let singleton: AuthClient;
let useMock: boolean;

export const getAuthClient = async () => {
  if (useMock) {
    return authClientMock;
  }

  if (!singleton) {
    singleton = await createAuthClient();
  }

  return singleton;
};

export const mockAuthClient = (value: boolean) => {
  useMock = value;
};
