import { NextI18nService } from '../../services';
import { GetInitialProps } from '../../types';
import { BaseLayout } from '../BaseLayout';

import { DefaultLayoutOptions } from './types';

export const buildGetInitialProps = (
  options?: DefaultLayoutOptions,
  list?: GetInitialProps[]
) =>
  BaseLayout.buildGetInitialProps([
    NextI18nService.getInitalPropsFn(options?.i18n),
    ...(list || []),
  ]);
