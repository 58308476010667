import { NextI18nService } from '../../services';
import { GetStaticPropsWithPrevious } from '../../types';
import { BaseLayout } from '../BaseLayout';

import { DefaultLayoutOptions } from './types';

export const buildGetStaticProps = (
  options?: DefaultLayoutOptions,
  list?: GetStaticPropsWithPrevious[]
) =>
  BaseLayout.buildGetStaticProps([
    NextI18nService.getStaticPropsFn(options?.i18n),
    ...(list || []),
  ]);
