import { type ColorSystemOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import merge from 'deepmerge';

import { lightScheme } from '../lightScheme';

export const createColorScheme = (scheme: ColorSystemOptions) => {
  const theme = createTheme({
    palette: merge.all<ColorSystemOptions>([lightScheme, scheme]).palette,
  });

  return { ...scheme, palette: theme.palette };
};
