import { OverrideComponent, MuiComponents } from './types';

export const MuiDivider: OverrideComponent = () => {
  const MuiDivider: MuiComponents['MuiDivider'] = {
    styleOverrides: {
      root: {
        width: '100%',
        height: 0,
      },
      vertical: {
        width: 0,
        height: '100%',
      },
    },
  };

  return {
    MuiDivider,
  };
};
