/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetCurrentUserInfoQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetCurrentUserInfoQuery = {
  __typename: 'query_root';
  users_user?:
    | Array<{
        __typename: 'users_user';
        id: number;
        picture_url?: string | undefined;
        first_name?: string | undefined;
        username?: string | undefined;
        last_name?: string | undefined;
        email?: string | undefined;
        is_superuser?: boolean | undefined;
        locale?: string | undefined;
        phone_verified?: boolean | undefined;
        team_official?:
          | {
              __typename: 'teams_official';
              id: number;
              teams?:
                | Array<{
                    __typename: 'teams_team_official';
                    id: number;
                    team_id?: number | undefined;
                    team?:
                      | {
                          __typename: 'teams_team';
                          id: number;
                          name?: string | undefined;
                          custom_name_in_competition?: string | undefined;
                        }
                      | undefined;
                  }>
                | undefined;
            }
          | undefined;
        club_admin?:
          | {
              __typename: 'clubs_admin';
              id: number;
              clubs?:
                | Array<{
                    __typename: 'clubs_club_admin';
                    id: number;
                    club_id?: number | undefined;
                  }>
                | undefined;
            }
          | undefined;
        media_official?:
          | {
              __typename: 'medias_official_reporter';
              id: number;
              media?: { __typename: 'medias_media'; id: number } | undefined;
            }
          | undefined;
        convocation_progress?:
          | Array<{
              __typename: 'users_convocation_progress';
              id: number;
              validated_event?: boolean | undefined;
              user_id?: number | undefined;
              has_role?: boolean | undefined;
              has_member?: boolean | undefined;
              has_admin?: boolean | undefined;
              club_id?: number | undefined;
              club_has_send_sms?: boolean | undefined;
              club_has_sms_credits?: boolean | undefined;
            }>
          | undefined;
      }>
    | undefined;
};

export const GetCurrentUserInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentUserInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users_user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'picture_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_superuser' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phone_verified' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team_official' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'teams' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team_id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'team' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'custom_name_in_competition',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'club_admin' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clubs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'club_id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media_official' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'media' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'convocation_progress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'validated_event' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'has_role' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'has_member' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'has_admin' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'club_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'club_has_send_sms' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'club_has_sms_credits' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCurrentUserInfoQuery,
  GetCurrentUserInfoQueryVariables
>;
