import { css } from '@emotion/react';
import { Menu } from '@mui/icons-material';
import { Box, AppBar, IconButton, Toolbar } from '@mui/material';
import { AppBarLogo } from '@scorenco/components';
import { RouteLink } from '@scorenco/core';

import { useNavigationContext } from './NavigationContext';
import { NavigationMessage } from './NavigationMessage';

export const NavigationAppBar = ({ ...rest }) => {
  const { drawerOpen, setDrawerOpen, noBurgerDrawer, AppBarActions } =
    useNavigationContext();

  return (
    <>
      <NavigationMessage sx={{ opacity: 0 }} />
      <AppBar {...rest}>
        <NavigationMessage />
        <Toolbar>
          {!noBurgerDrawer && (
            <IconButton
              sx={{ mr: 2, display: { md: 'none' } }}
              color="inherit"
              edge="start"
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              <Menu />
            </IconButton>
          )}

          <RouteLink href={{ routeKey: 'Home' }}>
            <Box
              component="a"
              css={(theme) => css`
                max-width: 30vw;

                ${theme.breakpoints.down('md')} {
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                }
              `}
            >
              <AppBarLogo />
            </Box>
          </RouteLink>

          {AppBarActions ? <AppBarActions /> : <div />}
        </Toolbar>
      </AppBar>
    </>
  );
};
