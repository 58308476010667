import { ColorSystemOptions } from '@mui/material';

import { COLORS, convertHexToRGBA } from '../../constants';

export const lightScheme: ColorSystemOptions = {
  palette: {
    common: {
      black: COLORS.BLUE_40,
      white: COLORS.BLUE_100,
      background: COLORS.BLUE_99,
    },
    text: {
      primary: COLORS.BLUE_40,
    },
    primary: {
      dark: COLORS.BLUE_30,
      main: COLORS.BLUE_40,
      light: COLORS.BLUE_50,
      lighter: COLORS.BLUE_99,
      contrastText: COLORS.BLUE_100,
    },
    secondary: {
      50: COLORS.GREEN_99,
      dark: COLORS.GREEN_20,
      main: COLORS.GREEN_30,
      light: COLORS.GREEN_40,
      lighter: COLORS.GREEN_99,
      contrastText: COLORS.GREEN_100,
    },
    premium: {
      light: COLORS.YELLOW_30,
      main: COLORS.YELLOW_40,
      dark: COLORS.YELLOW_50,
      contrastText: COLORS.BLUE_10,
    },
    info: {
      dark: COLORS.BLUE_30,
      main: COLORS.BLUE_40,
      light: COLORS.BLUE_50,
      contrastText: COLORS.BLUE_100,
    },
    success: {
      dark: COLORS.GREEN_20,
      main: COLORS.GREEN_30,
      light: COLORS.GREEN_40,
      contrastText: COLORS.GREEN_100,
    },
    warning: {
      light: COLORS.YELLOW_50,
      main: COLORS.YELLOW_30,
      dark: COLORS.YELLOW_10,
      contrastText: COLORS.BLUE_10,
    },
    error: {
      light: COLORS.RED_30,
      main: COLORS.RED_40,
      dark: COLORS.RED_50,
      contrastText: COLORS.RED_100,
    },
    background: {
      paper: COLORS.BLUE_100,
      default: COLORS.BLUE_99,
      body: COLORS.BLUE_99,
      paper2: COLORS.BLUE_99,
    },
    home: {
      main: COLORS.BLUE_90,
      dark: COLORS.BLUE_80,
    },
    away: {
      main: COLORS.YELLOW_70,
      dark: COLORS.YELLOW_40,
    },
    win: {
      main: COLORS.GREEN_30,
      contrastText: COLORS.BLUE_100,
    },
    even: {
      main: COLORS.BLUE_99,
      contrastText: COLORS.BLUE_40,
    },
    lost: {
      main: COLORS.RED_40,
      contrastText: COLORS.BLUE_100,
    },
    divider: COLORS.BLUE_90,
    action: {
      active: COLORS.BLUE_40,
      activatedOpacity: 1,
      disabled: convertHexToRGBA(COLORS.BLUE_40, 0.26),
      disabledBackground: COLORS.BLUE_99,
      focus: convertHexToRGBA(COLORS.BLUE_40, 0.12),
    },
    logo: {
      score_co: COLORS.BLUE_40,
      _n_: COLORS.GREEN_50,
      gradient: [COLORS.GREEN_30, COLORS.GREEN_50],
    },
    AppBar: {
      defaultBg: COLORS.BLUE_100,
    },
    Avatar: {
      defaultBg: COLORS.BLUE_40,
    },
  },
};
