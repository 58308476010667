import { Theme, css } from '@emotion/react';

import { vars } from '../vars';

const systemFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const customFonts = (...fonts: string[]) =>
  [...fonts, ...systemFonts].join(',');

export default (theme: Theme) => css`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Overpass:ital,wght@0,700;0,800;1,800&display=swap');

  :root {
    ${vars.font.base}: ${customFonts('Open Sans')};
    ${vars.font.alt}: ${customFonts('Overpass')};
  }

  body {
    color: ${theme.vars.palette.text.primary};
    background-color: ${theme.vars.palette.background.default};
    font-family: var(${vars.font.base});
  }
`;
