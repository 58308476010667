import { OverrideComponent, MuiComponents } from './types';

export const MuiTabs: OverrideComponent = () => {
  const MuiTabs: MuiComponents['MuiTabs'] = {
    styleOverrides: {
      indicator: {
        height: '3px',
      },
    },
  };

  return {
    MuiTabs,
  };
};
