import { useCallback } from 'react';

import { compile, parse } from 'path-to-regexp';

import { Locale, useTranslation } from '../i18n';

import { useRouterLinkResolverContext } from './RouterLinkResolverContext';
import { getRouterServiceSingleton } from './RouterService';
import { RouteLinkProps, RouterConfig, SingletonRouteLinkProps } from './types';

const getRouteConfig = (
  routeKey: string,
  locale: Locale,
  config?: RouterConfig
) =>
  config?.[routeKey]?.locales?.[locale] ||
  config?.[routeKey] ||
  getRouterServiceSingleton().config?.[routeKey]?.locales?.[locale] ||
  getRouterServiceSingleton().config?.[routeKey];

const isAbsoluteUrl = (url?: string) => {
  return url && /.*:\/\//.test(url);
};

export const useRouterLinkResolver = () => {
  const i18nProps = useTranslation();
  const linkResolverContext = useRouterLinkResolverContext();

  const routerLinkResolver = useCallback(
    ({
      locale,
      href,
      absoluteUrl,
    }: RouteLinkProps): SingletonRouteLinkProps | undefined => {
      if (!href) {
        return undefined;
      }

      const linkLocale = locale || i18nProps.locale;

      let hrefAsString = '';
      if (typeof href === 'string') {
        hrefAsString = href;
      } else {
        const routerConfig = getRouteConfig(
          href.routeKey,
          linkLocale,
          linkResolverContext?.config
        );

        if (routerConfig?.linkResolver) {
          hrefAsString = routerConfig.linkResolver({
            locale: linkLocale,
            ...(href.params || {}),
          }).href;
        } else if (typeof routerConfig?.pathname === 'string') {
          const toUrl = compile(routerConfig.pathname);
          const params = parse(routerConfig.pathname).reduce<string[]>(
            (acc, value) => {
              if (typeof value === 'string' || typeof value.name !== 'string') {
                return acc;
              }
              return [...acc, value.name];
            },
            ['locale']
          );

          const { urlParams, queryParams } = Object.entries(
            href.params || {}
          ).reduce(
            (acc, [name, value]) => {
              if (!value) {
                return acc;
              }

              // Si le paramètre est à insérer dans l'url
              if (params.indexOf(name) >= 0) {
                return {
                  ...acc,
                  urlParams: {
                    ...acc.urlParams,
                    [name]: value,
                  },
                };
              }
              // Sinon, c'est un query params
              else {
                return {
                  ...acc,
                  queryParams: {
                    ...acc.queryParams,
                    [name]: value,
                  },
                };
              }
            },
            {
              urlParams: {},
              queryParams: {},
            }
          );

          hrefAsString = [
            toUrl({
              locale: linkLocale,
              ...(urlParams || {}),
            })?.replace('--', '/'),
            new URLSearchParams(queryParams).toString(),
          ]
            .filter(Boolean)
            .join('?');
        }
      }

      if (!isAbsoluteUrl(hrefAsString) && absoluteUrl) {
        hrefAsString = [
          getRouterServiceSingleton().baseUrl?.replace(/\/$/, ''),
          hrefAsString,
        ]
          .filter(Boolean)
          .join('');
      }

      return {
        href: hrefAsString,
        locale: linkLocale,
      };
    },
    [linkResolverContext?.config, i18nProps.locale]
  );

  return { routerLinkResolver };
};
