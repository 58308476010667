import { useMemo } from 'react';

import { Types, UsePaginatedQueryResult } from '@scorenco/core';

const isToday = (date: string) =>
  date && new Date().toISOString().slice(0, 10) === date;

const hasEventToday = ({
  query,
}: {
  query: UsePaginatedQueryResult<any, any>;
}) => isToday(query.list?.[0]?.date);

type Group = {
  title: string;
  items: Types.Competitions_Event_Detail[];
  anchor?: boolean;
};

export const useGroupedEvents = ({
  query,
}: {
  query: UsePaginatedQueryResult<any, any>;
}) => {
  return useMemo(() => {
    const items = [];

    if (!hasEventToday({ query })) {
      items.push({ date: new Date().toISOString().slice(0, 10), id: -1 });
    }

    if (query.list?.length) {
      items.push(...query.list);
    }

    return items.reduce((groups, value) => {
      const clonedGroups = [...groups];
      const currentGroup = clonedGroups.pop();

      if (currentGroup?.title !== value.date) {
        const newGroup: Group = {
          title: value.date,
          items: [value],
        };

        if (isToday(value.date)) {
          if (currentGroup) {
            currentGroup.anchor = true;
          } else {
            newGroup.anchor = true;
          }
        }

        if (currentGroup) {
          clonedGroups.push(currentGroup);
        }

        return [...clonedGroups, newGroup];
      }

      return [
        ...clonedGroups,
        { ...currentGroup, items: [...currentGroup.items, value] },
      ];
    }, [] as Group[]);
  }, [query.count, query.list?.length]);
};
