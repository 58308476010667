import { OverrideComponent, MuiComponents } from './types';

export const MuiTableCell: OverrideComponent = () => {
  const MuiTableCell: MuiComponents['MuiTableCell'] = {
    styleOverrides: {
      root: {
        borderBottom: 'none',
      },
    },
  };

  return {
    MuiTableCell,
  };
};
