import { appEnhancer } from './appEnhancer';
import { getInitalPropsFn } from './getInitalPropsFn';
import { getServerSidePropsFn } from './getServerSidePropsFn';
import { getStaticPropsFn } from './getStaticPropsFn';
import { init } from './init';

export * from './types';

export const NextI18nService = {
  appEnhancer,
  getInitalPropsFn,
  getServerSidePropsFn,
  getStaticPropsFn,
  init,
};
