import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

type TabPanelProps = PropsWithChildren<{
  value: number;
  index: number;
}>;

export const TabPanel = ({
  children,
  value,
  index,
  ...other
}: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
