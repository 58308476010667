import React, {
  ReactElement,
  ReactEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { BoxProps } from '@mui/system';

import { Image, ImageProps } from '../Image';

export enum ImageStatus {
  Failed = 'error',
  Loading = 'loading',
  Loaded = 'loaded',
}

export const ImageLoader = (props: ImageLoaderProps) => {
  const imageRef = useRef<HTMLImageElement>();
  const {
    component = 'img',
    sx = {},
    src,
    Placeholder,
    onLoad,
    onError,
    ...rest
  } = props;
  const [status, setStatus] = useState(ImageStatus.Loading);

  const handleLoad: ReactEventHandler<HTMLImageElement> = useCallback(
    (event) => {
      setStatus(ImageStatus.Loaded);
      if (onLoad) {
        onLoad(event);
      }
    },
    [onLoad]
  );

  const handleError: ReactEventHandler<HTMLImageElement> = useCallback(
    (error) => {
      setStatus(ImageStatus.Failed);
      if (onError) {
        onError(error);
      }
    },
    [onError]
  );

  useEffect(() => {
    if (imageRef.current?.complete) {
      setStatus(ImageStatus.Loaded);
    }
  }, [imageRef.current]);

  const showPlaceholder = status !== ImageStatus.Loaded || !src;

  return (
    <>
      {src && (
        <Image
          ref={imageRef}
          component={component}
          src={src}
          onLoad={handleLoad}
          onError={handleError}
          sx={
            showPlaceholder
              ? {
                  ...sx,
                  position: 'absolute',
                  opacity: 0,
                }
              : sx
          }
          {...rest}
        />
      )}
      {showPlaceholder && Placeholder && <Placeholder sx={sx} {...rest} />}
    </>
  );
};
export type ImageLoaderProps = ImageProps & {
  Placeholder?: (props: BoxProps) => ReactElement | null;
};
