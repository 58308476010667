import { Box, Checkbox } from '@mui/material';
import type { marked } from 'marked';

import type { RendererFn } from '../type';

export const listItem: RendererFn<marked.Tokens.ListItem> = (
  { task, checked, tokens },
  { key, parse }
) => {
  return (
    <Box key={key} component="li">
      {task && <Checkbox checked={checked} />}
      {parse(tokens)}
    </Box>
  );
};
