export const primary = {
  shadow: '--color-primary-shadow',
};

export const shade2 = '--color-shade-2';
export const shade3 = '--color-shade-3';
export const shade6 = '--color-shade-6';
export const shade7 = '--color-shade-7';

export const gold = '--color-gold';

export const primary100 = '--color-primary-100';
export const primary200 = '--color-primary-200';
export const primary300 = '--color-primary-300';
export const primary500 = '--color-primary-500';
export const primary600 = '--color-primary-600';

export const secondary100 = '--color-secondary-100';
export const secondary200 = '--color-secondary-200';
export const secondary300 = '--color-secondary-300';
export const secondary500 = '--color-secondary-500';
