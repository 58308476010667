import { ReactElement, useMemo } from 'react';

import { match, MatchFunction } from 'path-to-regexp';

import { Locale } from '../i18n';

import { HrefProp, RouteLinkProps, RouterConfig } from './types';
import { useRouterLinkResolver } from './useRouterLinkResolver';

type RouterServiceSingleton = {
  useRouteInfo: typeof useRouteInfo;
  useRouteNavigation: () => {
    push: (options: { pathname: string; locale: Locale }) => Promise<boolean>;
    replace: (options: {
      pathname: string;
      locale: Locale;
    }) => Promise<boolean>;
    back: () => Promise<boolean>;
  };
  RouteLink: (
    props: Omit<RouteLinkProps, 'href'> & { href: string }
  ) => ReactElement | null;
  config?: RouterConfig;
  baseUrl?: string;
};

/** 
TODO: 
- remplacer les useRouter de next/router
  > back()
  > router.asPath === href pour savoir si href est l'url active
  > push({pathname, query}|string, undefined, { locale }) où 
    > pathname est l'url de redirection
    > query les query params (facultatif)
    > locale est la langue à forcer (utilisé pour le changement de langue)
  > router.query pour récupérer les queryparams + params d'url
  > replace(url:string)
- remplacer les Link de next/link
- remplacer les useLocation de react/router
- remplacer les useRouter de @scorenco/app
  > match(href:string)
  > push(url:string, { locale, routerDirection })
- remplacer les useContext(IonRouterContext) dans @scorenco/app
- mettre un système pour avoir les urls générés à partir de clé
- helpers pour générer les href avec routerLink
- ajouter la locale dans l'url 
- gérer les redirections (rewrites) vers la page
*/

let singleton: RouterServiceSingleton & {
  pathsToPage?: { routeKey: string; match: MatchFunction }[];
};

export const getRouterServiceSingleton = () => {
  if (!singleton) {
    throw new Error('RouterService must be initialize before using it');
  }

  return singleton;
};

const toHrefProp = (location: string) => {
  const pathsToPage = getRouterServiceSingleton().pathsToPage;

  if (pathsToPage) {
    for (const { match, routeKey } of pathsToPage) {
      const matched = match(location.split('?')[0]);
      if (matched) {
        return {
          routeKey,
          params: matched.params,
        };
      }
    }
  }
  return undefined;
};

/**
 * Retourne les infos concernant la route courante
 */
export const useRouteInfo = <T = Record<string, string | undefined>,>(): {
  pathname: string;
  asPath: string;
  query: T;
  href?: HrefProp;
} => {
  const { pathname, asPath, query } =
    getRouterServiceSingleton().useRouteInfo();

  const res = useMemo(() => {
    const href = toHrefProp(pathname);

    return {
      pathname,
      asPath,
      query: { ...(href?.params || {}), ...query } as T,
      href,
    };
  }, [asPath, JSON.stringify(query)]);

  return res;
};

/**
 * Retourne les méthodes pour naviguer entre les écrans
 */
export const useRouteNavigation = (): {
  push: (options: RouteLinkProps) => Promise<boolean>;
  replace: (options: RouteLinkProps) => Promise<boolean>;
  back: () => Promise<boolean>;
} => {
  const routeNavigation = getRouterServiceSingleton().useRouteNavigation();
  const { routerLinkResolver } = useRouterLinkResolver();

  const res = useMemo(() => {
    return {
      push: async (options: RouteLinkProps) => {
        const linkProps = routerLinkResolver(options);

        if (linkProps) {
          return await routeNavigation.push({
            ...options,
            pathname: linkProps.href,
            locale: linkProps.locale,
          });
        }
        return Promise.resolve(false);
      },
      replace: async (options: RouteLinkProps) => {
        const linkProps = routerLinkResolver(options);
        if (linkProps) {
          return await routeNavigation.replace({
            ...options,
            pathname: linkProps.href,
            locale: linkProps.locale,
          });
        }
        return Promise.resolve(false);
      },
      back: async () => await routeNavigation.back(),
    };
  }, [routerLinkResolver]);

  return res;
};

export const RouterService = {
  init: (routerService: RouterServiceSingleton) => {
    singleton = routerService;

    if (routerService.config) {
      singleton.pathsToPage = Object.entries(routerService.config).reduce<
        {
          routeKey: string;
          match: MatchFunction;
        }[]
      >((acc, [routeKey, value]) => {
        if (!value.pathname) {
          return acc;
        }

        return [
          ...acc,
          {
            routeKey,
            match: match(value.pathname, { decode: decodeURIComponent }),
          },
        ];
      }, []);
    }
  },
};
