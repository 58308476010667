type InternalEnvVarsResult = {
  required: () => InternalEnvVarsResult;
  modify: (
    modifiyFn: (value: string | undefined) => string | undefined
  ) => InternalEnvVarsResult;
  asString: () => { get: () => string };
  asBool: () => { get: () => boolean };
  asNumber: () => { get: () => number };
};

const internalEnvVars = (
  key: string,
  value?: any,
  state: {
    required?: boolean;
    modifiyFn?: (value: any) => any;
    asString?: boolean;
    asBool?: boolean;
    asNumber?: boolean;
  } = {}
): InternalEnvVarsResult => {
  const required = () => {
    return internalEnvVars(key, value, { ...state, required: true });
  };

  const modify = (
    modifiyFn: (value: string | undefined) => string | undefined
  ) => {
    return internalEnvVars(key, value, {
      ...state,
      modifiyFn,
    });
  };

  const get = () => {
    let res = value;

    if (state.modifiyFn) {
      res = state.modifiyFn(value);
    }

    if (state.required && value === undefined) {
      throw new Error(`"${key}" is a required variable, but it was not set.`);
    }

    return res;
  };

  const asString = () => {
    return {
      get: () => {
        const res = get();
        return value ? String(res) : '';
      },
    };
  };

  const asBool = () => {
    return {
      get: () => {
        const res = get();
        return String(res) === 'true';
      },
    };
  };

  const asNumber = () => {
    return {
      get: () => {
        const res = get();
        return value ? Number(res) : NaN;
      },
    };
  };

  return {
    required,
    modify,
    asString,
    asBool,
    asNumber,
  };
};

/**
 * Permet de typer et verifier la variable d'environnement donnée, plutôt que
 * d'utiliser directement process.env.MA_VARIABLE
 */
export const envVars = (key: string, value?: string) =>
  internalEnvVars(key, value);
