import { AuthentificationErrorCode } from '../../types';
import { AuthClient } from '../types';

type UserType = {
  email: string;
  password: string;
  needConfirmSignUp: boolean;
};

const USERS: Record<string, UserType> = {
  'admin@scorenco.com': {
    email: 'admin@scorenco.com',
    password: 'admin',
    needConfirmSignUp: false,
  },
  'club@scorenco.com': {
    email: 'club@scorenco.com',
    password: 'club',
    needConfirmSignUp: false,
  },
  'user@scorenco.com': {
    email: 'user@scorenco.com',
    password: 'user',
    needConfirmSignUp: false,
  },
};

const createSession = (user: UserType) => {
  return Promise.resolve({
    getIdToken: () => ({
      payload: {},
      getJwtToken: () => user && `id-token:jwt:${user.email}`,
      getExpiration: () => 0,
      getIssuedAt: () => 0,
      decodePayload: () => ({}),
    }),
    getRefreshToken: () => ({
      getToken: () => user && `refresh-token:jwt:${user.email}`,
    }),
    getAccessToken: () => ({
      payload: {},
      getJwtToken: () => user && `access-token:jwt:${user.email}`,
      getExpiration: () => 0,
      getIssuedAt: () => 0,
      decodePayload: () => ({}),
    }),
    isValid: () => true,
  });
};

const state: {
  session?: UserType;
  users: typeof USERS;
} = { users: { ...USERS } };

export const resetAuthClient = () => {
  state.session = undefined;
  state.users = { ...USERS };
};

export const authClientMock: AuthClient = {
  signIn: async (email: string, password: string) => {
    const user = state.users[email];
    if (!user) {
      return Promise.reject({
        code: AuthentificationErrorCode.NotAuthorizedException,
      });
    }

    if (user.password !== password) {
      return Promise.reject({
        code: AuthentificationErrorCode.NotAuthorizedException,
      });
    }

    if (user.needConfirmSignUp) {
      return Promise.reject({
        code: AuthentificationErrorCode.UserNotConfirmedException,
      });
    }

    state.session = user;

    return Promise.resolve();
  },

  signOut: () => {
    state.session = undefined;
    return Promise.resolve();
  },

  signUp: async (options: string | Record<string, any>) => {
    if (typeof options === 'string') {
      return;
    }

    const user = state.users[options.username];
    if (user) {
      return Promise.reject({
        code: AuthentificationErrorCode.UsernameExistsException,
      });
    }

    const newUser = {
      email: options.username,
      password: options.password,
      needConfirmSignUp: true,
    };
    state.users = {
      ...state.users,
      [options.username]: newUser,
    };
    state.session = newUser;

    // Nous n'utilisons pas le retour de la fonction signup.
    return Promise.resolve({} as any);
  },

  confirmSignUp: async (username: string, code: string) => {
    const user = state.users[username];
    if (!user) {
      return Promise.reject({
        code: AuthentificationErrorCode.InvalidParameterException,
      });
    }

    if (code !== '123') {
      return Promise.reject({
        code: AuthentificationErrorCode.CodeMismatchException,
      });
    }

    user.needConfirmSignUp = false;

    return Promise.resolve();
  },

  forgotPasswordSubmit: async (
    username: string,
    code: string,
    password: string
  ) => {
    const user = state.users[username];
    if (!user) {
      return Promise.reject({
        code: AuthentificationErrorCode.NotAuthorizedException,
      });
    }

    if (code !== '123') {
      return Promise.reject({
        code: AuthentificationErrorCode.CodeMismatchException,
      });
    }

    user.password = password;

    return Promise.resolve('');
  },

  currentAuthenticatedUser: async () => {
    if (!state.session) {
      return Promise.reject({
        code: AuthentificationErrorCode.NotAuthorizedException,
      });
    }
    return Promise.resolve({
      refreshSession: (
        refreshToken: any,
        callback: (error: any, session: any) => Promise<any>
      ) => {
        if (state.session) {
          callback(undefined, Promise.resolve(createSession(state.session)));
        }
      },
    });
  },

  currentSession: async () => {
    if (!state.session) {
      return Promise.reject({
        code: AuthentificationErrorCode.NotAuthorizedException,
      });
    }
    return Promise.resolve(createSession(state.session));
  },

  federatedSignIn: (provider: any) => {
    if (typeof provider === 'string') {
      return Promise.resolve({} as any);
    }

    state.session = state.users['user@scorenco.com'];

    // Nous n'utilisons pas le retour de la fonction federatedSignIn.
    return Promise.resolve({} as any);
  },

  forgotPassword: async (username: string) => {
    const user = state.users[username];
    if (!user) {
      return Promise.reject({
        code: AuthentificationErrorCode.NotAuthorizedException,
      });
    }

    return Promise.resolve();
  },

  resendSignUp: async () => {
    return Promise.resolve();
  },
};
