import { Translations } from '@scorenco/components';

export const T_KEYS = {
  ...Translations.T_KEYS,

  DEFAULT_HTML_TITLE: 'DEFAULT_HTML_TITLE',

  MENU_CHANGE_LOCALE: 'MENU_CHANGE_LOCALE',
  NO_RESULT: 'NO_RESULT',

  APPSTORES_GPLAY_LOGO: 'APPSTORES_GPLAY_LOGO',
  APPSTORES_GPLAY_ALT: 'APPSTORES_GPLAY_ALT',
  APPSTORES_APPSTORE_LOGO: 'APPSTORES_APPSTORE_LOGO',
  APPSTORES_APPSTORE_ALT: 'APPSTORES_APPSTORE_ALT',

  EVENT_HERO_TITLE: 'EVENT_HERO_TITLE',
  EVENT_HERO_TEXT: 'EVENT_HERO_TEXT',
  EVENT_SEO_PAGE_TITLE: 'EVENT_SEO_PAGE_TITLE',
  EVENT_SEO_PAGE_DESCRIPTION: 'EVENT_SEO_PAGE_DESCRIPTION',

  DATE_FNS_FORMAT: 'DATE_FNS_FORMAT',
  TODAY: 'TODAY',

  SELECT_SPORT: 'SELECT_SPORT',

  COMPETITION_AREA_1: 'COMPETITION_AREA_1',
  COMPETITION_AREA_2: 'COMPETITION_AREA_2',
  COMPETITION_AREA_3: 'COMPETITION_AREA_3',
  COMPETITION_AREA_4: 'COMPETITION_AREA_4',
  COMPETITION_AREA_5: 'COMPETITION_AREA_5',
  COMPETITION_AREA_6: 'COMPETITION_AREA_6',

  M_GENDER: 'M_GENDER',
  F_GENDER: 'F_GENDER',
  N_GENDER: 'N_GENDER',
};

export type T_KEYS = typeof T_KEYS;
