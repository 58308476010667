import { envVars } from '../../helpers';

export const IDENTITY_POOL_ID = envVars(
  'NEXT_PUBLIC_IDENTITY_POOL_ID',
  process.env.NEXT_PUBLIC_IDENTITY_POOL_ID
)
  .required()
  .asString();

export const USER_POOL_REGION = envVars(
  'NEXT_PUBLIC_USER_POOL_REGION',
  process.env.NEXT_PUBLIC_USER_POOL_REGION
)
  .required()
  .asString();

export const USER_POOL_ID = envVars(
  'NEXT_PUBLIC_USER_POOL_ID',
  process.env.NEXT_PUBLIC_USER_POOL_ID
).asString();

export const USER_POOL_CLIENT_ID = envVars(
  'NEXT_PUBLIC_USER_POOL_CLIENT_ID',
  process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID
).asString();

export const AUTH_COOKIE_DOMAIN = envVars(
  'NEXT_PUBLIC_AUTH_COOKIE_DOMAIN',
  process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN
).asString();

export const IDP_DOMAIN = envVars(
  'NEXT_PUBLIC_IDP_DOMAIN',
  process.env.NEXT_PUBLIC_IDP_DOMAIN
).asString();

export const REDIRECT_SIGN_IN = envVars(
  'NEXT_PUBLIC_REDIRECT_SIGN_IN',
  process.env.NEXT_PUBLIC_REDIRECT_SIGN_IN
)
  .required()
  .modify((value) => {
    if (typeof value === 'string') {
      if (
        process.env.NEXT_PUBLIC_TARGET_PLATFORM === 'ios' ||
        process.env.NEXT_PUBLIC_TARGET_PLATFORM === 'android'
      ) {
        return value
          .replace('http://localhost:3000', 'com.scorenco.app.dev://localhost')
          .replace(
            'https://staging-app.scorenco.com',
            'com.scorenco.app.staging://localhost'
          )
          .replace('https://app.scorenco.com', 'com.scorenco.app://localhost');
      }
    }
    return value;
  })
  .asString();

export const REDIRECT_SIGN_OUT = envVars(
  'NEXT_PUBLIC_REDIRECT_SIGN_OUT',
  process.env.NEXT_PUBLIC_REDIRECT_SIGN_OUT
)
  .required()
  .modify((value) => {
    if (typeof value === 'string') {
      if (
        process.env.NEXT_PUBLIC_TARGET_PLATFORM === 'ios' ||
        process.env.NEXT_PUBLIC_TARGET_PLATFORM === 'android'
      ) {
        return value
          .replace('http://localhost:3000', 'com.scorenco.app.dev://localhost')
          .replace(
            'https://staging-app.scorenco.com',
            'com.scorenco.app.staging://localhost'
          )
          .replace('https://app.scorenco.com', 'com.scorenco.app://localhost');
      }
    }
    return value;
  })
  .asString();
