import { GetInitialPropsWithPrevious } from '../../types';

import { translationsLoader } from './translationsLoader';
import { NextI18nServiceOptions } from './types';

export const getInitalPropsFn = (
  options?: NextI18nServiceOptions
): GetInitialPropsWithPrevious => {
  const loadTranslations = translationsLoader();

  return async ({ locale }) => {
    // Récupération des traductions côté serveur
    const i18nProps = await loadTranslations({
      locale: locale || options?.defaultLocale,
      namespaces: options?.namespaces,
    });

    return i18nProps;
  };
};
