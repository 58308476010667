import { Fragment } from 'react';

import type { marked } from 'marked';

import type { RendererFn } from '../type';

import { unescape } from './utils';

export const escape: RendererFn<marked.Tokens.Escape> = ({ text }, { key }) => {
  return <Fragment key={key}>{unescape(text)}</Fragment>;
};
