import { PropsWithChildren, useEffect } from 'react';

import { show } from './client';

/**
 * Gestion du RGPD
 *
 * Plus d'info sur l'implémentation ici : https://docs.sfbx.io/configuration/notice-implementation/web-cmp/
 */
export const CMPService = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    window.configSFBXAppConsent = {
      appKey: process.env.NEXT_PUBLIC_SFBX_APPKEY,
      global: true,
    };

    const script = document.createElement('script');
    script.src = `https://cdn.appconsent.io/loader-clear.js`;
    script.async = true;
    script.defer = true;
    script.id = 'appconsent';
    document.head.appendChild(script);
  }, []);

  return <>{children}</>;
};

CMPService.show = () => {
  show({ jumpAt: 'privacy' });
};
