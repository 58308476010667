export enum AuthentificationState {
  Initialize = 'initialize',
  SignUp = 'sign-up',
  SignOut = 'sign-out',
  SignIn = 'sign-in',
  SignedOut = 'signed-out',
  SignedIn = 'signed-in',
  ConfirmSignUp = 'confirm-sign-up',
  ForgotPassword = 'forgot-password',
  ConfirmForgotPassword = 'confirm-forgot-password',
  SelectSport = 'select-sport',
  SelectClub = 'select-club',
  SelectTeam = 'select-team',
  BecomeFan = 'become-fan',
}

export enum AuthentificationStatus {
  Loading = 'loading',
  Authenticated = 'authenticated',
  NotAuthenticated = 'not-authenticated',
}

export enum AuthentificationIdentityProvider {
  Facebook = 'Facebook',
  Apple = 'Apple',
}

export enum AuthentificationErrorCode {
  NotAuthorizedException = 'NotAuthorizedException',
  InvalidParameterException = 'InvalidParameterException',
  CodeMismatchException = 'CodeMismatchException',
  ExpiredCodeException = 'ExpiredCodeException',
  UsernameExistsException = 'UsernameExistsException',
  NetworkError = 'NetworkError',
  UserNotConfirmedException = 'UserNotConfirmedException',
  InvalidPasswordException = 'InvalidPasswordException',
}
