import Head from 'next/head';

import { AppPropsWithLayout } from '../types';

/**
 * Fonction permettant d'ajouter les fonctionnalités de base pour le composant _app
 * de next. Ce composant est appelé côté client et côté serveur.
 */
export const AppLayout = (appProps: AppPropsWithLayout) => {
  const { Component, pageProps } = appProps;
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      {getLayout(<Component {...pageProps} />, appProps)}
    </>
  );
};
