import { PropsWithChildren, useMemo } from 'react';

import { CacheProvider, Global } from '@emotion/react';
import { EmotionCache } from '@emotion/utils';
import { CssBaseline } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { nonNullable } from '@scorenco/core';
import merge from 'deepmerge';

import { globals } from '../../style/globals';
import { createTheme } from '../createTheme';

const defaultTheme = createTheme();

type GlobalThemeProviderProps = PropsWithChildren<{
  theme?: typeof defaultTheme;
  cache: EmotionCache;
}>;

/**
 * Initialisation globale du theme, à utiliser à la création de l'application.
 */
export const GlobalThemeProvider = ({
  children,
  theme,
  cache,
}: GlobalThemeProviderProps) => {
  const mergedTheme = useMemo(
    () =>
      merge.all<typeof defaultTheme>([defaultTheme, theme].filter(nonNullable)),
    [theme]
  );

  return (
    <CacheProvider value={cache}>
      <CssVarsProvider theme={mergedTheme} defaultMode="light">
        <Global styles={globals} />
        <CssBaseline />
        {children}
      </CssVarsProvider>
    </CacheProvider>
  );
};
