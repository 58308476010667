import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { RouterConfig } from './types';

type RouterLinkResolverContextProps = {
  config?: RouterConfig;
};

const RouterLinkResolverContext = createContext<
  RouterLinkResolverContextProps | undefined
>(undefined);

export const useRouterLinkResolverContext = ():
  | RouterLinkResolverContextProps
  | undefined => {
  const context = useContext(RouterLinkResolverContext);

  return context;
};

export const RouterLinkResolverProvider = ({
  config,
  children,
}: PropsWithChildren<{
  config: RouterConfig;
}>) => {
  const value = useMemo(() => ({ config }), [config]);

  return (
    <RouterLinkResolverContext.Provider value={value}>
      {children}
    </RouterLinkResolverContext.Provider>
  );
};
