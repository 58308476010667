import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/system';

export type WrapperProps = BoxProps & {
  noMargin?: boolean;
};

export const Wrapper = ({ noMargin, sx, ...rest }: WrapperProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: noMargin
          ? ' 100%'
          : {
              xs: `calc(100% - ${theme.spacing(2)} * 2)`,
              md: `calc(100% - ${theme.spacing(3)} * 2)`,
              lg: `calc(100% - ${theme.spacing(4)} * 2)`,
            },
        maxWidth: theme.sizes.maxWidth,
        mx: 'auto',
        ...sx,
      }}
      {...rest}
    />
  );
};
