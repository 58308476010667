import { marked } from 'marked';

export interface SocialToken {
  type: 'social';
  icons: string[];
  hrefs: string[];
  tokens: marked.Token[];
}

/**
 * Permet de créer une icône de réseau social : ![icon](socialName, url, alt) où
 * socialName correspond au nom du réseau social (exemple: twitter, facebook, linkedin)
 * url est le lien du réseau social et alt est la description alternative de l'image
 */
export const socialExtension: marked.RendererExtension &
  marked.TokenizerExtension = {
  name: 'social',
  level: 'inline',
  start: (src) => {
    return src.match(/!\[/)?.index || -1;
  },
  tokenizer: (src) => {
    const rule = new RegExp('^!\\[(.+?)\\]\\[(.+?)\\]');
    const match = rule.exec(src);
    if (match) {
      const icons = match[1]
        .trim()
        .replaceAll(' ', '')
        .toLowerCase()
        .split(',');
      const hrefs = match[2].trim().replaceAll(' ', '').split(',');
      const tokens = icons.flatMap((icon) => marked.Lexer.lexInline(icon));

      return {
        type: 'social',
        raw: match[0],
        icons,
        hrefs,
        tokens,
      };
    }

    return undefined;
  },
  renderer: (token) => {
    return token.icons
      .map(
        (icon: string, index: number) =>
          `<a href="${token.hrefs[index]}">${icon}</a>`
      )
      .join('');
  },
};
