import { FieldPolicy } from '@apollo/client';

import { Types } from '../../../../types';
import { mergeArrayByField } from '../../mergeArrayByField';

import { customSingleQueries } from './customSingleQueries';
import { hasuraSingleQueries } from './hasuraSingleQueries';

export const queries: Record<string, FieldPolicy> = {
  ...hasuraSingleQueries,
  ...customSingleQueries,

  lives_post: {
    keyArgs: ['order_by', 'where'],
    merge: (existing, incoming, options) => {
      return mergeArrayByField<Types.Lives_Post>('id')(
        existing,
        incoming,
        options
      ).sort((a, b) => {
        const dateA = options.readField<string>('_created', a) || '';
        const dateB = options.readField<string>('_created', b) || '';
        return dateB.localeCompare(dateA);
      });
    },
  },
  competitions_event: {
    keyArgs: ['order_by', 'where'],
    merge: mergeArrayByField('id'),
  },
  competitions_event_detail_by_team_id: {
    keyArgs: ['args'],
    merge: mergeArrayByField('id'),
  },
  competitions_event_detail_by_user_favorite_teams: {
    keyArgs: ['args'],
    merge: mergeArrayByField('id'),
  },
  competitions_event_detail_by_club_id: {
    keyArgs: ['args'],
    merge: mergeArrayByField('id'),
  },
  news_news: {
    keyArgs: ['args'],
    merge: mergeArrayByField('id'),
  },
};
