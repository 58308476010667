import React from 'react';

import { Box, BoxProps } from '@mui/system';

export const Image = React.forwardRef(
  (
    { component = 'img', src, alt = '', loading = 'lazy', ...rest }: ImageProps,
    ref
  ) => {
    return (
      <Box
        component={component}
        src={src}
        alt={alt}
        loading={loading}
        ref={ref}
        {...rest}
      />
    );
  }
);

export type ImageProps = JSX.IntrinsicElements['img'] & BoxProps;
