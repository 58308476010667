import { useEffect } from 'react';

import { CalendarMonth } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { CrispService, RouteLink } from '@scorenco/core';
import type { marked } from 'marked';

import { RendererFn } from '../type';

import { link } from './link';

const CalendlyButton = ({ linkToken, buttonProps }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      if (window.Calendly) {
        window.Calendly.initPopupWidget({
          url: linkToken.href,
        });
      } else {
        console.error(
          "Calendly n'est pas encore chargé. Veuillez réessayer dans quelques secondes."
        );
      }
    }
  };

  return (
    <>
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <a href="#" onClick={handleClick}>
        <Button {...buttonProps} endIcon={<CalendarMonth />}>
          {linkToken.text || 'Planifier une démo'}
        </Button>
      </a>
    </>
  );
};

const MARKDOWN_TO_BUTTON_PROPS: Record<string, ButtonProps> = {
  principal: {
    variant: 'contained',
    color: 'secondary',
  },
  secondaire: {
    variant: 'contained',
    color: 'primary',
  },
};

declare global {
  interface Window {
    Calendly: any;
  }
}

// ... le reste du code existant ...

export const button: RendererFn<
  marked.Tokens.Link & { buttonType: string; message?: string }
> = ({ buttonType, ...linkToken }, utils) => {
  const buttonProps = MARKDOWN_TO_BUTTON_PROPS[buttonType] || {};

  if (buttonType === 'lien') {
    return link(linkToken, utils);
  }

  if (linkToken.href.startsWith('#crisp')) {
    return (
      <Button
        onClick={() => {
          CrispService.show();

          if (linkToken.message) {
            CrispService.sendMessageToUser(linkToken.message);
          }
        }}
        key={utils.key}
        {...buttonProps}
      >
        {utils.parse(linkToken.tokens)}
      </Button>
    );
  }

  if (linkToken.href.startsWith('https://calendly.com')) {
    return <CalendlyButton linkToken={linkToken} buttonProps={buttonProps} />;
  }

  return (
    <RouteLink href={linkToken.href} key={utils.key}>
      <Button {...buttonProps}>{utils.parse(linkToken.tokens)}</Button>
    </RouteLink>
  );
};
