import { FragmentType } from './types';

// On utilise maintenant les noms d'action du back.
// Cette partie n'est plus utilisée
export const lives_action: FragmentType<'lives_action'> = {
  fields: {
    translation_key: {
      read: (_, { readField }) => {
        const actionType = readField<string>('action_type');
        const result = ['ACTION', actionType?.toUpperCase()];
        if (actionType === 'half') {
          const data = readField<any>('data');
          if (data) {
            result.push(
              `PERIOD_${data.period_number}`,
              data.end ? 'END' : 'START'
            );
          }
        }
        if (actionType === 'extra') {
          const data = readField<any>('data');
          if (data) {
            result.push(
              `PERIOD_${data.period_number}`,
              data.end ? 'END' : 'START'
            );
          }
        }
        return result.join('_');
      },
    },
  },
};
