import { Fragment } from 'react';

import type { marked } from 'marked';

import type { RendererFn } from '../type';

import { unescape } from './utils';

export const text: RendererFn<marked.Tokens.Text> = (
  { tokens, text },
  { parse, key }
) => {
  return (
    <Fragment key={key}>{tokens ? parse(tokens) : unescape(text)}</Fragment>
  );
};
