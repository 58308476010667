import { Close } from '@mui/icons-material';
import {
  Toolbar,
  ToolbarProps,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import { Markdown } from '@scorenco/components';

import { useNavigationContext } from './NavigationContext';

export const NavigationMessage = ({ sx, ...rest }: ToolbarProps) => {
  const { message, hideMessage } = useNavigationContext();

  if (!message) {
    return null;
  }

  return (
    <Toolbar
      sx={{
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
        py: 1,
        minHeight: 0,
        ...sx,
      }}
      variant="dense"
      {...rest}
    >
      <Stack gap={2} direction="row" alignItems="center" width="100%">
        <Typography
          sx={{
            color: 'primary.contrastText',
            lineHeight: 1.2,
            a: {
              color: 'inherit',
            },
            flex: 1,
            paddingLeft: '2.75rem',
            textAlign: 'center',
          }}
          variant="body2"
        >
          <Markdown value={message} isInline />
        </Typography>
        <IconButton size="small" onClick={hideMessage}>
          <Close sx={{ color: 'white', fontSize: 'inherit' }} />
        </IconButton>
      </Stack>
    </Toolbar>
  );
};
