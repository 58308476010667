import { Box } from '@mui/material';
import type { marked } from 'marked';

import { RendererFn } from '../type';

export const code: RendererFn<marked.Tokens.Code> = (
  token,
  { key, parse, renderer }
) => {
  return (
    <Box key={key} component="pre">
      {renderer.codespan(token, { key, parse, renderer })}
    </Box>
  );
};
