import { userSession } from '../../helpers';

import { getAuthClient } from './auth-client';

export const getRefreshToken = async (bypassCache = false) => {
  try {
    const authClient = await getAuthClient();
    const session = await authClient.fetchAuthSession({
      forceRefresh: bypassCache,
    });
    const newToken = session.tokens?.idToken?.toString();
    const oldToken = userSession.getToken();

    if (oldToken !== newToken) {
      userSession.setToken(newToken ? `Bearer ${newToken}` : '');
    }

    return newToken ? `Bearer ${newToken}` : '';
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
};
