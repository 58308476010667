import { getConfig } from './config';

type CustomServerSideTranslations = (options?: {
  locale?: string;
  namespaces?: string[];
}) => Promise<any>;

export const translationsLoader = () => {
  let customServerSideTranslations: CustomServerSideTranslations = () =>
    Promise.resolve({});

  // La fonction serverSideTranslations est concu pour s'executer côté serveur uniquement.
  if (typeof window === 'undefined') {
    customServerSideTranslations = async (options) => {
      const config = getConfig();

      const { serverSideTranslations } = await import(
        'next-i18next/serverSideTranslations'
      );

      return await serverSideTranslations(
        options?.locale || config.i18n.defaultLocale,
        ['snc-components', 'snc-core', ...(options?.namespaces || ['common'])],
        getConfig()
      );
    };
  }

  return customServerSideTranslations;
};
