import { PropsWithChildren, useEffect, useMemo } from 'react';

import type {
  UseTranslationOptions,
  UseTranslationResponse,
} from 'react-i18next';

import { userSession } from '../../helpers';

import { toLocale } from './types';

type I18nSingleton = {
  useTranslation: (
    ns?: any,
    options?: UseTranslationOptions<unknown>
  ) => UseTranslationResponse<string, unknown>;
};

let singleton: I18nSingleton;

export const useTranslation = (namespace?: string) => {
  if (!singleton) {
    throw new Error('You need to call I18nService.init before');
  }

  const { t, i18n } = singleton.useTranslation(namespace);
  const result = useMemo(() => {
    return {
      t,
      i18n,
      locale: toLocale(i18n.language),
    };
  }, [t, i18n.language]);

  return result;
};

const Provider = ({ children }: PropsWithChildren) => {
  const { locale } = useTranslation();

  useEffect(() => {
    userSession.setLocale(locale);
  }, [locale]);

  return <>{children}</>;
};

const init = (i18nSingleton: any) => {
  singleton = i18nSingleton;
};

export const I18nService = {
  Provider,
  init,
};
