/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

import { Types } from '@/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type LiveListenNewMessagesSubscriptionVariables = Types.Exact<{
  liveId: Types.Scalars['Int']['input'];
}>;

export type LiveListenNewMessagesSubscription = {
  __typename: 'subscription_root';
  lives_post?:
    | Array<{
        __typename: 'lives_post';
        id: number;
        _created?: any | undefined;
        data?: any | undefined;
        picture?: string | undefined;
        action_id?: number | undefined;
        live_id?: number | undefined;
        deleted?: boolean | undefined;
        team_official_reporter_id?: number | undefined;
        spectator?:
          | {
              __typename: 'lives_spectator';
              id: number;
              name?: string | undefined;
              picture_url?: string | undefined;
              user_id?: number | undefined;
            }
          | undefined;
        action?:
          | {
              __typename: 'lives_action';
              id: number;
              action_type?: string | undefined;
              icon?: string | undefined;
              data?: any | undefined;
              name?: string | undefined;
              rank?: any | undefined;
              translation_key?: string | undefined;
            }
          | undefined;
      }>
    | undefined;
};

export type LiveListenInfosSubscriptionVariables = Types.Exact<{
  liveId: Types.Scalars['Int']['input'];
}>;

export type LiveListenInfosSubscription = {
  __typename: 'subscription_root';
  lives_live_last_update?:
    | Array<{
        __typename: 'lives_live_last_update';
        live_id?: number | undefined;
        last_post_update?: any | undefined;
      }>
    | undefined;
};

export type EventListenInfosSubscriptionVariables = Types.Exact<{
  eventId: Types.Scalars['Int']['input'];
}>;

export type EventListenInfosSubscription = {
  __typename: 'subscription_root';
  competitions_event_last_update?:
    | Array<{
        __typename: 'competitions_event_last_update';
        event_id?: number | undefined;
        last_score_update?: any | undefined;
      }>
    | undefined;
};

export type LiveListenScoreSubscriptionVariables = Types.Exact<{
  eventId: Types.Scalars['Int']['input'];
}>;

export type LiveListenScoreSubscription = {
  __typename: 'subscription_root';
  competitions_event_detail_by_id?:
    | Array<{
        __typename: 'competitions_event_detail';
        id: number;
        time?: any | undefined;
        date?: any | undefined;
        teams?: any | undefined;
        status?: string | undefined;
      }>
    | undefined;
};

export const LiveListenNewMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'LiveListenNewMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liveId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lives_post' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_created' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'live_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'liveId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LivePostItem' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LivePostItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'lives_post' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '_created' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spectator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'picture_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
          { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'action' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'translation_key' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'client' },
                    },
                  ],
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'live_id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleted' },
            directives: [
              { kind: 'Directive', name: { kind: 'Name', value: 'client' } },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team_official_reporter_id' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LiveListenNewMessagesSubscription,
  LiveListenNewMessagesSubscriptionVariables
>;
export const LiveListenInfosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'LiveListenInfos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'liveId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lives_live_last_update' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'live_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'liveId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'live_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_post_update' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LiveListenInfosSubscription,
  LiveListenInfosSubscriptionVariables
>;
export const EventListenInfosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'EventListenInfos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'competitions_event_last_update' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'event_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eventId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'event_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'last_score_update' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EventListenInfosSubscription,
  EventListenInfosSubscriptionVariables
>;
export const LiveListenScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'LiveListenScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'competitions_event_detail_by_id' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'args' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'eventId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teams' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LiveListenScoreSubscription,
  LiveListenScoreSubscriptionVariables
>;
