import { OverrideComponent, MuiComponents } from './types';

export const MuiToolbar: OverrideComponent = () => {
  const MuiToolbar: MuiComponents['MuiToolbar'] = {
    styleOverrides: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  };

  return {
    MuiToolbar,
  };
};
