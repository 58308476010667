import { OverrideComponent, MuiComponents } from './types';

export const MuiTableContainer: OverrideComponent = (theme) => {
  const MuiTableContainer: MuiComponents['MuiTableContainer'] = {
    defaultProps: {},
    styleOverrides: {
      root: {
        border: `1px solid ${theme.vars.palette.divider}`,
        borderRadius: '0.5rem',
        backgroundColor: theme.vars.palette.background.paper,
      },
    },
  };

  return {
    MuiTableContainer,
  };
};
