import { useTheme } from '@emotion/react';
import Head from 'next/head';

import { JsonLd } from '../JsonLd';

export const GlobalSeo = () => {
  const theme = useTheme();

  return (
    <Head>
      {/* Default metadata */}
      <meta charSet="utf-8" />
      {/* <meta name="robots" content="index,follow" key="robots" /> */}

      {/* Facebook metadata */}
      <meta property="fb:app_id" content="161787037327963" key="fb:app_id" />

      {/* Twitter metadata */}
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta
        name="twitter:creator"
        content="@Score_n_co"
        key="twitter:creator"
      />
      <meta name="twitter:site" content="Score'n'co" key="twitter:site" />
      <meta
        name="twitter:account_id"
        content="2190610868"
        key="twitter:account_id"
      />

      {/* OG metadata */}
      <meta property="og:site_name" content="Score'n'co" key="og:site_name" />
      <meta property="og:type" content="website" key="og:type" />
      <meta name="og:image:width" content="1200" key="og:image:width" />
      <meta name="og:image:height" content="630" key="og:image:height" />

      {/* Apps/Mobile metadata */}
      <meta
        name="mobile-web-app-capable"
        content="yes"
        key="mobile-web-app-capable"
      />
      <meta
        name="apple-mobile-web-app-capable"
        content="yes"
        key="apple-mobile-web-app-capable"
      />
      <meta
        name="apple-mobile-web-app-title"
        content="Score'n'co"
        key="apple-mobile-web-app-title"
      />
      <meta
        name="application-name"
        content="Score'n'co"
        key="application-name"
      />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content={theme.vars.palette.primary.main}
        key="apple-mobile-web-app-status-bar-style"
      />

      {/* Application metadata */}
      {/*
        <meta name="twitter:app:name:iphone" content="Score'n'co" key="twitter:app:name:iphone" />
        <meta name="twitter:app:id:iphone" content="816395945" key="twitter:app:id:iphone" />
        <meta property="al:ios:app_name" content="Score'n'co" key="al:ios:app_name" />
        <meta property="al:ios:app_store_id" content="816395945" key="al:ios:app_store_id" />
        <meta property="al:android:package" content="com.scorenco.app" key="al:android:package" />
        <meta property="al:android:app_name" content="Score'n'co" key="al:android:app_name"/>
      */}

      {/* Icones */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/images/favicons/apple-touch-icon.png"
        key="apple-touch-icon"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/images/favicons/favicon-32x32.png"
        key="icon-32x32"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/images/favicons/favicon-16x16.png"
        key="icon-16x16"
      />
      <link rel="manifest" href="/site.webmanifest" key="manifest" />
      <link
        rel="mask-icon"
        href="/images/favicons/safari-pinned-tab.svg"
        color={theme.vars.palette.primary.main}
        key="mask-icon"
      />
      <meta
        name="msapplication-TileColor"
        content={theme.vars.palette.primary.main}
        key="msapplication-TileColor"
      />
      <meta
        name="theme-color"
        content={theme.vars.palette.primary.main}
        key="theme-color"
      />

      {/* JSON ld */}
      <JsonLd
        value={{
          '@context': 'http://schema.org',
          '@type': 'Organization',
          image: {
            '@type': 'ImageObject',
            url: `${process.env.NEXT_PUBLIC_WEB_URL}/images/default-shared-image.png`,
            width: '1200',
            height: '630',
          },
          url: process.env.NEXT_PUBLIC_WEB_URL,
          sameAs: [
            'https://www.facebook.com/scorenco',
            'https://twitter.com/Score_n_co',
            'https://www.linkedin.com/company/score-n-co/',
          ],
          name: "Score'n'co",
          address: {
            '@type': 'PostalAddress',
            addressLocality: 'Nantes',
            addressRegion: 'FR',
            postalCode: '44200',
            streetAddress: '12 Bd Victor Hugo 44200 NANTES',
          },
          publishingPrinciples: `${process.env.NEXT_PUBLIC_WEB_URL}/conditions-generales`,
          logo: {
            '@type': 'ImageObject',
            url: `${process.env.NEXT_PUBLIC_WEB_URL}/images/logo-scorenco.png`,
            width: 900,
            height: 199,
          },
        }}
      />
    </Head>
  );
};
