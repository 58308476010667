import { OverrideComponent, MuiComponents } from './types';

export const MuiTab: OverrideComponent = () => {
  const MuiTab: MuiComponents['MuiTab'] = {
    styleOverrides: {
      root: {
        fontWeight: 900,
      },
    },
  };

  return {
    MuiTab,
  };
};
