import { FieldPolicy, Reference, StoreObject } from '@apollo/client';

// Permet de s'assurer que les équipes à domicile et à l'exterieur sont bien
// ordonnées.
export const orderIncomingTeams = (
  fieldPolicy: FieldPolicy = {}
): FieldPolicy => ({
  ...fieldPolicy,
  merge: (existing, incoming, options) => {
    const orderedIncoming = incoming?.reduce(
      (acc: string | any[], team: Reference | StoreObject | undefined) => {
        // La première équipe est ajoutée au tableau
        if (acc.length === 0) {
          return [team];
        }

        const role = options.readField('role', team);

        if (!role) {
          throw new Error('role is a required field for ordering');
        }

        // En fonction du role de la seconde équipe, on l'ajoute avant ou après
        // dans le tableau
        return role === 'home' ? [team, ...acc] : [...acc, team];
      },
      []
    );

    if (typeof fieldPolicy.merge === 'function') {
      return fieldPolicy.merge(existing, orderedIncoming, options);
    }

    return orderedIncoming;
  },
});
