import { useId } from 'react';

import { css, useTheme } from '@emotion/react';
import { Box, BoxProps } from '@mui/system';

export type ScorencoLogoProps = BoxProps & {
  small?: boolean;
  forceColor?: string;
};

export const ScorencoLogo = ({
  small,
  forceColor,
  ...rest
}: ScorencoLogoProps) => {
  const theme = useTheme();
  const id = useId();

  if (small) {
    return (
      <Box
        component="svg"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 218 255.7"
        xmlSpace="preserve"
        {...rest}
      >
        <defs>
          <path
            id={`svg-id-${id}`}
            d="m100 199-28-28v-6l51-51h6l28 28c2 2 2 5 0 7l-50 50c-2 2-5 2-7 0m-11-69-28-28v-6l50-51c2-2 5-2 7 0l28 28c2 2 2 5 0 7l-50 50c-2 2-5 2-7 0M21 12S0 56 0 101c0 99 90 146 106 154h6c16-8 106-54 106-154 0-52-21-89-21-89S167 0 109 0C52 0 21 12 21 12"
          />
        </defs>
        <clipPath id={`clip-path-${id}`}>
          <use xlinkHref={`#svg-id-${id}`} style={{ overflow: 'visible' }} />
        </clipPath>
        <g
          style={{
            clipPath: `url(#clip-path-${id})`,
          }}
        >
          <linearGradient
            id={`gradient-${id}`}
            x1="-449.1"
            x2="-446"
            y1="-18.1"
            y2="-18.1"
            gradientTransform="rotate(180 -14561 -526) scale(65.3)"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset="0"
              stopColor={forceColor || theme.vars.palette.logo.gradient[1]}
            />
            <stop
              offset="1"
              stopColor={forceColor || theme.vars.palette.logo.gradient[0]}
            />
          </linearGradient>
          <path
            d="M0 0h218v256H0V0z"
            style={{
              fill: `url(#gradient-${id})`,
            }}
          />
        </g>
      </Box>
    );
  }

  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1156 255.7"
      css={(theme) => css`
        .cls-2 {
          fill: ${forceColor || theme.vars.palette.logo.score_co};
        }
      `}
      {...rest}
    >
      <defs>
        <linearGradient
          id={`gradient-${id}`}
          x1="-62.3"
          x2="-59.2"
          y1="680.3"
          y2="680.3"
          gradientTransform="matrix(-65.3 0 0 65.3 -3861 -44327)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0"
            stopColor={forceColor || theme.vars.palette.logo.gradient[1]}
          />
          <stop
            offset="1"
            stopColor={forceColor || theme.vars.palette.logo.gradient[0]}
          />
        </linearGradient>
        <clipPath id={`clip-path-${id}`} transform="translate(-14 -8)">
          <path
            d="m114 207-28-28a5 5 0 0 1 0-6l51-51a5 5 0 0 1 6 0l28 28a5 5 0 0 1 0 7l-50 50a5 5 0 0 1-7 0m-11-69-28-28a5 5 0 0 1 0-6l50-51a5 5 0 0 1 7 0l28 28a5 5 0 0 1 0 7l-50 50a5 5 0 0 1-7 0M35 20s-21 44-21 89c0 99 90 146 106 154a6 6 0 0 0 6 0c16-8 106-54 106-154 0-52-21-89-21-89S181 8 123 8C66 8 35 20 35 20"
            style={{ fill: 'none' }}
          />
        </clipPath>
      </defs>
      <g>
        <path
          d="M1047 130c0-29 23-53 55-53s54 23 54 52v1c0 29-23 53-55 53s-54-24-54-53m86 0c0-18-13-33-32-33s-31 14-31 32v1c0 18 13 33 32 33 18 0 31-15 31-33m-107 21c-6 5-11 8-18 9-19 4-36-11-36-30v-1c0-17 12-31 29-31 10 0 17 4 25 11a50 50 0 0 1 13-18c-9-8-20-13-38-13-30 0-52 23-52 52s22 51 51 51c17 0 28-5 38-14a50 50 0 0 1-12-16"
          className="cls-2"
        />
        <path
          d="M950 55h-15v45l23-22V63a8 8 0 0 0-8-8m-64 22v21h11v43l-16-22-32-41h-20v83h-12v20h35v-68l3 4 45 64h20V77h-34zm-79-22h-16v45l23-22V63a8 8 0 0 0-7-8"
          css={(theme) => css`
            fill: ${forceColor || theme.vars.palette.logo._n_};
          `}
        />
        <path
          d="M696 78h78v20h-55v20h49v20h-49v23h58v20h-81V78zm-112 49v20h12v34h23v-34h18l22 34h26l-25-38c13-5 22-16 22-34v1c0-9-3-17-9-22-7-7-17-10-30-10h-47v37h23V98h22c11 0 18 4 18 14v2s-6 14-17 13h-23Zm-120 3c0-29 23-53 54-53 32 0 55 23 55 52v1c0 29-23 53-55 53s-54-24-54-53m86 0c0-18-13-33-32-33s-31 14-31 32v1c0 18 13 33 31 33s32-15 32-33m-105 24a34 34 0 0 1-14 6c-19 4-36-11-36-30v-1c0-17 12-31 29-31 8 0 15 3 21 8a51 51 0 0 1 14-17c-9-6-19-11-35-11-31 0-52 23-52 52s22 51 51 51c15 0 26-4 35-11a50 50 0 0 1-13-16m-165 13 14-17c9 8 19 13 31 13 9 0 15-4 15-10s-3-9-20-13c-21-6-34-11-34-32 0-19 15-32 36-32 16 0 29 5 39 13l-12 18c-9-7-18-10-27-10s-13 4-13 9c0 7 4 9 22 14 20 5 32 12 32 30v1c0 21-16 32-38 32a67 67 0 0 1-45-16"
          className="cls-2"
        />
      </g>
      <g style={{ clipPath: `url(#clip-path-${id})` }}>
        <path d="M0 0h218v256H0z" style={{ fill: `url(#gradient-${id})` }} />
      </g>
    </Box>
  );
};
