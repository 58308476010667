import type { marked } from 'marked';

import type { RendererFn } from '../type';

export const em: RendererFn<marked.Tokens.Em> = (
  { tokens },
  { parse, key }
) => {
  return <em key={key}>{parse(tokens)}</em>;
};
