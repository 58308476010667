import { Types } from '../../../../types';

import { FragmentType } from './types';

export const teams_team_in_season: FragmentType<'teams_team_in_season'> = {
  fields: {
    read_logo: {
      read: (_, { readField }) => {
        const logo = readField<string>('logo');
        if (logo) {
          return logo;
        }

        const team = readField<Types.Teams_Team>('team');
        if (team) {
          const club = readField<Types.Clubs_Club>('club', team);
          if (club) {
            return readField<string>('logo', club);
          }
        }

        return undefined;
      },
    },
  },
};
