import { FieldPolicy } from '@apollo/client';

import { byPkQueries } from '../generated';

/**
 * Permet d'activer le cache redirect sur les queries autogénéré d'hasura.
 *
 * Pour plus d'info : https://www.apollographql.com/docs/react/caching/advanced-topics#cache-redirects
 */
export const hasuraSingleQueries = {
  ...byPkQueries.reduce<Record<string, FieldPolicy>>(
    (acc, fragmentName) => ({
      ...acc,
      [`${fragmentName}_by_pk`]: {
        read: (existing, { args, toReference }) =>
          toReference({
            __typename: fragmentName,
            id: args?.id,
          }),
      },
    }),
    {}
  ),
};
