import { RouterService } from '@scorenco/core';

import { RouteLink } from './RouteLink';
import { useRouteInfo } from './useRouteInfo';
import { useRouteNavigation } from './useRouteNavigation';

export const init = (
  options: Omit<
    Parameters<typeof RouterService.init>[0],
    'RouteLink' | 'useRouteInfo' | 'useRouteNavigation'
  >
) => {
  return RouterService.init({
    RouteLink,
    useRouteInfo,
    useRouteNavigation,
    ...options,
  });
};
