import { OverrideComponent, MuiComponents } from './types';

export const MuiAccordion: OverrideComponent = (theme) => {
  const MuiAccordion: MuiComponents['MuiAccordion'] = {
    styleOverrides: {
      root: {
        boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.07)',

        '&:before': {
          display: 'none',
        },

        '.MuiAccordionSummary-expandIconWrapper': {
          color: theme.vars.palette.text.primary,
        },
      },
      rounded: {
        borderTopLeftRadius: '0.5rem !important',
        borderTopRightRadius: '0.5rem !important',
        borderBottomLeftRadius: '0.5rem !important',
        borderBottomRightRadius: '0.5rem !important',
      },
      gutters: {
        '&:not(:last-child)': {
          marginBottom: '1rem',
        },
      },
    },
    defaultProps: {
      disableGutters: true,
      elevation: 0,
    },
  };

  return {
    MuiAccordion,
  };
};
