/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

export const byPkQueries = [
  'apps_version',
  'billing_club_customer',
  'billing_customer',
  'clubhouse_club_house',
  'clubhouse_screen',
  'clubhouse_template',
  'clubs_admin',
  'clubs_club_admin',
  'clubs_club',
  'clubs_club_detail',
  'clubs_organization',
  'clubs_planner_settings',
  'clubs_season',
  'clubs_sport',
  'competitions_competition',
  'competitions_competition_detail',
  'competitions_competition_has_fans',
  'competitions_competition_has_teams',
  'competitions_event',
  'competitions_event_detail',
  'competitions_event_referee',
  'competitions_event_with_same_teams',
  'competitions_fan',
  'competitions_league',
  'competitions_league_detail',
  'competitions_level',
  'competitions_level_for_season',
  'competitions_level_for_season_detail',
  'competitions_playing_team_bar_manager',
  'competitions_playing_team',
  'competitions_playing_team_info',
  'competitions_playing_team_referee',
  'competitions_playing_team_scoring_table',
  'competitions_playing_team_stadium_manager',
  'competitions_ranking',
  'competitions_ranking_detail',
  'competitions_round',
  'emails_event',
  'emails_message',
  'lives_action',
  'lives_live',
  'lives_live_hit',
  'lives_live_hit_count',
  'lives_live_player',
  'lives_live_team',
  'lives_media_official_reporter',
  'lives_official_reporter',
  'lives_post',
  'lives_spectator',
  'lives_team_official_reporter',
  'lives_thank_you',
  'lives_tournament_reporter',
  'medias_media',
  'medias_official_reporter',
  'members_export',
  'members_group',
  'members_member',
  'members_member_in_group',
  'members_team_official_reporter',
  'news_news',
  'notifications_endpoint',
  'permissions_member_permission',
  'permissions_member_role',
  'permissions_role',
  'permissions_role_permission',
  'places_city',
  'places_country',
  'places_department',
  'places_equipment',
  'places_place',
  'places_region',
  'scraping_championships_clubchampionshipexception',
  'sms_club_sms_credit',
  'sms_event',
  'sms_message',
  'socials_facebook',
  'socials_post',
  'sponsors_category',
  'sponsors_sponsor',
  'sponsors_team_sponsor',
  'stats_action',
  'tasks_candidate_group',
  'tasks_candidate_team',
  'tasks_job',
  'tasks_member_task_count',
  'tasks_task',
  'tasks_team_task',
  'tasks_volunteer',
  'teams_authorized_group',
  'teams_authorized_team',
  'teams_club_group',
  'teams_fan',
  'teams_level',
  'teams_official',
  'teams_team_action_permission',
  'teams_team',
  'teams_team_detail',
  'teams_team_has_fans',
  'teams_team_in_season',
  'teams_team_in_season_has_member',
  'teams_team_in_season_translation',
  'teams_team_official',
  'tournaments_challenge',
  'tournaments_challenge_rule',
  'tournaments_engagement',
  'tournaments_game',
  'tournaments_official_reporter_v2',
  'tournaments_phase',
  'tournaments_place',
  'tournaments_ranking',
  'tournaments_ranking_points',
  'tournaments_ranking_rule',
  'tournaments_team',
  'tournaments_tournament',
  'urls_url_detail',
  'users_convocation_progress',
  'users_member_invitation',
  'users_user',
  'widgets_club_settings',
  'widgets_convocation_settings',
  'widgets_widget',
  'widgets_widget_has_competition',
  'widgets_widget_has_team',
  'widgets_widget_live',
  'widgets_widget_team',
];
