import { css } from '@emotion/react';
import {
  Facebook,
  Instagram,
  X,
  LinkedIn,
  YouTube,
  Apple,
  Android,
} from '@mui/icons-material';
import { Stack, Typography, IconButton, Divider } from '@mui/material';
import { Box } from '@mui/system';
import { AppBarLogo, Section, Wrapper } from '@scorenco/components';
import { RouteLink } from '@scorenco/core';

import { isChVersion } from '../../utils';

import { FooterMenu } from './FooterMenu';

const year = new Date().getFullYear();

export const Footer = () => {
  return (
    <Section color="default">
      <Wrapper>
        <Stack spacing={4}>
          {!isChVersion() && <FooterMenu />}

          <Divider />

          <Box
            css={(theme) => css`
              display: flex;
              flex-direction: column;
              align-items: center;

              ${theme.breakpoints.up('sm')} {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
              }
            `}
          >
            <AppBarLogo />

            <Stack direction="row" alignItems="center">
              <RouteLink href="https://apps.apple.com/fr/app/scorenco-sport-en-direct/id816395945">
                <IconButton size="large" color="primary">
                  <Apple />
                </IconButton>
              </RouteLink>
              <RouteLink href="https://play.google.com/store/apps/details?id=com.scorenco.app">
                <IconButton size="large" color="primary">
                  <Android />
                </IconButton>
              </RouteLink>
              <Divider
                orientation="vertical"
                sx={{
                  margin: '0 0.75rem',
                  height: '2rem',
                }}
              />
              <RouteLink href="https://www.facebook.com/scorenco">
                <IconButton size="large" color="primary">
                  <Facebook fontSize="inherit" />
                </IconButton>
              </RouteLink>
              <RouteLink href="https://www.instagram.com/score_n_co">
                <IconButton size="large" color="primary">
                  <Instagram fontSize="inherit" />
                </IconButton>
              </RouteLink>
              <RouteLink href="https://www.linkedin.com/company/score-n'co">
                <IconButton size="large" color="primary">
                  <LinkedIn fontSize="inherit" />
                </IconButton>
              </RouteLink>
              <RouteLink href="https://twitter.com/score_n_co">
                <IconButton size="large" color="primary">
                  <X fontSize="inherit" />
                </IconButton>
              </RouteLink>
              <RouteLink href="https://www.youtube.com/c/Scorenco">
                <IconButton size="large" color="primary">
                  <YouTube fontSize="inherit" />
                </IconButton>
              </RouteLink>
            </Stack>

            <Typography
              css={(theme) => css`
                width: 100%;
                text-align: center;

                ${theme.breakpoints.up('sm')} {
                  text-align: left;
                }
              `}
            >
              © 2014 - {year} Score'n'co
            </Typography>
          </Box>
        </Stack>
      </Wrapper>
    </Section>
  );
};
