import { OverrideComponent, MuiComponents } from './types';

export const MuiTableRow: OverrideComponent = (theme) => {
  const MuiTableRow: MuiComponents['MuiTableRow'] = {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.vars.palette.divider}`,
        '&:last-child': {
          borderBottom: 0,
        },
      },
    },
  };

  return {
    MuiTableRow,
  };
};
