import { Typography, TypographyProps } from '@mui/material';
import type { marked } from 'marked';

import type { RendererFn } from '../type';

const MARKDOWN_TO_VARIANT: TypographyProps['variant'][] = [
  'h2',
  'h3',
  'h6',
  'overline',
];

export const heading: RendererFn<marked.Tokens.Heading> = (
  { tokens, depth },
  { key, parse }
) => {
  const variant = MARKDOWN_TO_VARIANT[depth - 1] || undefined;
  return (
    <Typography
      key={key}
      marginX="auto"
      marginY={3}
      component={variant === 'h2' || variant === 'h3' ? variant : 'p'}
      variant={variant}
      sx={
        depth < 3
          ? {
              strong: {
                color: 'secondary.main',
              },
            }
          : {}
      }
    >
      {parse(tokens)}
    </Typography>
  );
};
