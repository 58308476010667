import { ParsedUrlQuery } from 'querystring';

import merge from 'deepmerge';
import { GetStaticPaths, GetStaticPathsResult } from 'next';

type GetStaticPathsWithPrevious<P extends ParsedUrlQuery = ParsedUrlQuery> = (
  ...args: [...Parameters<GetStaticPaths<P>>, GetStaticPathsResult]
) => ReturnType<GetStaticPaths<P>>;

export type BuildGetStaticPaths<P extends ParsedUrlQuery = ParsedUrlQuery> = (
  getStaticPathsArray?: GetStaticPathsWithPrevious<P>[]
) => GetStaticPaths<P>;

/**
 * Permet de construire la fonction GetStaticPaths pour une page. A utiliser que
 * s'il y a un slug dans l'url.
 *
 * Cette fonction est appelé au moment du build afin de générer les urls des pages
 * construites au moment du build.
 */
export const buildGetStaticPaths: BuildGetStaticPaths =
  (getStaticPathsArray = []) =>
  async (context) => {
    return await getStaticPathsArray.reduce(
      async (promisedAcc, getStaticPaths) => {
        const acc = await promisedAcc;

        const result = await getStaticPaths(context, acc);

        return merge(acc, result);
      },
      Promise.resolve({
        paths: [],
        fallback: 'blocking',
      } as GetStaticPathsResult<any>)
    );
  };
