import { HrefProp, Types } from '@scorenco/core';

import { PageSeo } from './PageSeo';

type StrapiPageSeoProps = {
  seo: Types.ComponentSharedSeo;
  url: HrefProp;
};

export const StrapiPageSeo = ({ seo, url }: StrapiPageSeoProps) => {
  return (
    <PageSeo
      title={seo.metaTitle as string}
      description={seo.metaDescription as string}
      sharedImage={seo.metaImage?.data?.attributes?.url}
      url={url}
    />
  );
};
