import { gql } from '@apollo/client';

import { Types } from '../../../../types';
import { orderIncomingTeams } from '../utils';

import { FragmentType } from './types';

export const lives_live: FragmentType<'lives_live'> = {
  merge: (existing, incoming, options) => {
    const eventId = options.readField<number>('event_id', incoming);
    if (!eventId) {
      return incoming;
    }

    let status = options.readField('status', incoming);
    if (!status) {
      status = options.readField('status', existing);
    }

    let teams = options.readField<Types.Lives_Live_Team[]>('teams', incoming);
    if (!teams) {
      teams = options.readField<Types.Lives_Live_Team[]>('teams', existing);
    }

    const eventDetailTeams = teams?.map((team) => {
      const role = options.readField('role', team);
      const score = options.readField('score', team);
      const data = options.readField('data', team);

      return {
        role,
        score,
        data,
      };
    });

    // Lorsque l'on recoit des données sur le score via lives_live,
    // on met à jour competitions_event_detail
    options.cache.updateFragment(
      {
        id: `competitions_event_detail:${eventId}`,
        fragment: gql`
          fragment UpdateCompetitionsEventDetail on competitions_event_detail {
            teams
            status
          }
        `,
      },
      (data) => ({
        ...data,
        teams: ['home', 'away'].map((_, idx) => ({
          ...(data?.teams?.[idx] || {}),
          ...(eventDetailTeams?.[idx] || {}),
        })),
        status,
      })
    );

    return incoming;
  },
  fields: {
    teams: orderIncomingTeams(),
  },
};
