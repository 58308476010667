import { marked } from 'marked';

import { buttonExtension } from './extension/buttonExtension';
import { socialExtension } from './extension/socialExtension';
import { defaultRenderer } from './renderer';
import { ParseFn, RendererFn } from './type';

marked.use({ extensions: [buttonExtension, socialExtension] });

type Options = { isInline?: boolean; renderer?: Record<string, RendererFn> };

const parse: ParseFn = (tokens, renderer = defaultRenderer) => {
  return tokens.map((token, index) => {
    const tokenType = token.type as keyof typeof renderer;
    const rendererFn = renderer?.[tokenType];
    if (typeof rendererFn !== 'function') {
      return null;
    }

    return rendererFn(token, {
      key: `${tokenType}-${index}`,
      parse: (tokens, currentRenderer = renderer) =>
        parse(tokens, currentRenderer),
      renderer,
    });
  });
};

export const parseMarkdown = (
  value: string,
  { isInline, renderer = defaultRenderer }: Options = {}
) => {
  const tokens = isInline
    ? marked.Lexer.lexInline(value, {
        ...marked.defaults,
        breaks: false,
        gfm: true,
      })
    : marked.Lexer.lex(value, {
        ...marked.defaults,
        gfm: true,
      });

  return parse(tokens, renderer);
};
