import { FragmentType } from './types';

export const lives_post: FragmentType<'lives_post'> = {
  fields: {
    deleted: {
      read: (value) => {
        return value || false;
      },
    },
  },
};
