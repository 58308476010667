import { Stack, Typography, Button } from '@mui/material';
import { Wrapper } from '@scorenco/components';
import { RouteLink } from '@scorenco/core';

import { RouteKey } from '../../routes';
import { PageSeo } from '../Seo';

export const ErrorComponent = () => {
  return (
    <Wrapper>
      <PageSeo title="Erreur" description="" url="erreur" />
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={2}
        my={4}
        minHeight="25rem"
      >
        <Typography variant="h1">Erreur</Typography>
        <Typography variant="subtitle1" mb={2}>
          Une erreur s'est produite. Veuillez reessayer ou nous contacter si le
          problème continue.
        </Typography>
        <RouteLink href={{ routeKey: RouteKey.Home }}>
          <Button variant="contained">Retour à la page d'accueil</Button>
        </RouteLink>
      </Stack>
    </Wrapper>
  );
};
