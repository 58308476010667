import { NextI18nService } from '../../services';
import { GetServerSidePropsWithPrevious } from '../../types';
import { BaseLayout } from '../BaseLayout';

import { DefaultLayoutOptions } from './types';

export const buildGetServerSideProps = (
  options?: DefaultLayoutOptions,
  list?: GetServerSidePropsWithPrevious[]
) =>
  BaseLayout.buildGetServerSideProps([
    NextI18nService.getServerSidePropsFn(options?.i18n),
    ...(list || []),
  ]);
