import { useMemo } from 'react';

import { Locale } from '@scorenco/core';
import { useRouter } from 'next/router';

/**
 * Hook à utiliser pour la navigation programmatique.
 */
export const useRouteNavigation = () => {
  const router = useRouter();

  const navigation = useMemo(
    () => ({
      push: async (options: { pathname: string; locale: Locale }) => {
        return await router.push(options.pathname, undefined, {
          locale: options.locale.split('-')[0],
        });
      },
      replace: async (options: { pathname: string; locale: Locale }) =>
        await router.replace(options.pathname, undefined, {
          locale: options.locale.split('-')[0],
        }),
      back: async () => {
        router.back();
        return Promise.resolve(true);
      },
    }),
    [router.asPath, router.query]
  );

  return navigation;
};
