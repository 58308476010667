import { PropsWithChildren, useEffect } from 'react';

import { Types } from '../../types';

import {
  init,
  hide,
  show,
  addSegment,
  sendMessageToUser,
  setUserInfo,
  sendMessageFromUser,
  getChatIframeUrl,
} from './client';
import { CRISP_WEBSITE_ID } from './env-vars';

/**
 * Crisp est un service de relation client.
 * Cette integration est inspirée du projet : https://github.com/Mergifyio/react-crisp
 */

type CrispServiceProps = PropsWithChildren<{
  /** Ne pas afficher le chat après le chargement du script */
  hideOnStart?: boolean;
}>;

const insertScript = (options: { hideOnStart?: boolean }) => {
  const scriptUrl = 'https://client.crisp.chat/l.js';
  const scripts = document.querySelector(`script[src='${scriptUrl}']`);

  if (scripts === null) {
    const script = document.createElement('script');

    script.src = scriptUrl;
    script.async = true;

    document.head.appendChild(script);

    init();

    if (options?.hideOnStart) {
      hide();
    }
  }
};

export const CrispService = ({ children, hideOnStart }: CrispServiceProps) => {
  useEffect(() => {
    insertScript({ hideOnStart });
  }, []);

  return <>{children}</>;
};

CrispService.init = () => {
  if (typeof window !== 'undefined') {
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID.get();
  }
};

export const reportComment = (arg: {
  post: Types.Lives_Post;
  url: string;
  reason: string;
}) => {
  sendMessageFromUser(
    `Signalement du commentaire (${arg.post.id}) de l'utilisateur ${arg.post
      .spectator?.name} (${arg.post.spectator?.user_id}) à ${new Date(
      arg.post._created
    ).toLocaleTimeString()} le ${new Date(
      arg.post._created
    ).toLocaleDateString()}. (${arg.url}) \n Motif : ${
      arg.reason
    } \n Datas : ${JSON.stringify(arg.post.data)}`
  );
};

export const ClubAdminRequest = (arg: {
  userId: string;
  clubId: number;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
}) => {
  sendMessageFromUser(
    `Demande de gestion de club
    Club : https://admin.scorenco.com/${arg.clubId}
    Utilisateur : ${arg.first_name} ${arg.last_name}
    Id: ${arg.userId}
    Email : ${arg.email}
    Rôle : ${arg.role}`
  );
};

CrispService.show = show;
CrispService.hide = hide;
CrispService.addSegment = addSegment;
CrispService.sendMessageToUser = sendMessageToUser;
CrispService.sendMessageFromUser = sendMessageFromUser;
CrispService.setUserInfo = setUserInfo;
CrispService.getChatIframeUrl = getChatIframeUrl;
CrispService.reportComment = reportComment;
CrispService.ClubAdminRequest = ClubAdminRequest;
