import { Theme, css } from '@emotion/react';
import { Box, BoxProps } from '@mui/system';

import { vars } from '../../style';

export type SectionProps = BoxProps & {
  color?: 'default' | 'body' | 'dark';
};

export const Section = ({
  color = 'default',
  children,
  ...rest
}: SectionProps) => (
  <Box
    component="section"
    css={[
      (theme) => css`
        &:before {
          display: block;
          margin-bottom: ${theme.spacing(4)};
          content: 'section-start';
          font-size: 0;
          line-height: 0;
        }

        &:after {
          display: block;
          margin-top: ${theme.spacing(4)};
          content: 'section-end';
          font-size: 0;
          line-height: 0;
        }

        ${theme.breakpoints.up('md')} {
          &:before {
            margin-bottom: ${theme.spacing(6)};
          }

          &:after {
            margin-top: ${theme.spacing(6)};
          }
        }

        ${theme.breakpoints.up('lg')} {
          &:before {
            margin-bottom: ${theme.spacing(8)};
          }

          &:after {
            margin-top: ${theme.spacing(8)};
          }
        }
      `,
      color === 'default' &&
        ((theme: Theme) => css`
          background: ${theme.vars.palette.background.paper};
          color: ${theme.vars.palette.text.primary};
        `),
      color === 'body' &&
        ((theme: Theme) => css`
          background: ${theme.vars.palette.background.default};
          color: ${theme.vars.palette.text.primary};
        `),
      color === 'dark' &&
        css`
          background: var(${vars.color.shade2});
          color: var(${vars.color.shade7});
        `,
    ]}
    {...rest}
  >
    {children}
  </Box>
);
