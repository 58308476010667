import { FieldFunctionOptions, StoreObject } from '@apollo/client';

export const mergeArrayByField = <T>(field: keyof T) => (
  existing: readonly (T & StoreObject)[],
  incoming: readonly (T & StoreObject)[],
  { readField, mergeObjects }: FieldFunctionOptions
): (T & StoreObject)[] => {
  const merged = existing ? existing.slice(0) : [];
  const indexes: Record<string, number> = Object.create(null);
  if (existing) {
    existing.forEach((item, index) => {
      indexes[readField(field.toString(), item) as string] = index;
    });
  }
  incoming.forEach((item) => {
    const name = readField(field.toString(), item) as string;
    const index = indexes[name];
    if (typeof index === 'number')
      merged[index] = mergeObjects(merged[index], item);
    else {
      indexes[name] = merged.length;
      merged.push(item);
    }
  });
  return merged;
};
