import { useMemo } from 'react';

import { Box, BoxProps } from '@mui/system';

import { parseMarkdown } from './parseMarkdown';
import { defaultRenderer } from './renderer';
import { RendererFn } from './type';

export type MarkdownProps = BoxProps & {
  value?: string;
  isInline?: boolean;
  renderer?: Record<string, RendererFn>;
};

export const Markdown = ({
  value = '',
  isInline,
  renderer = defaultRenderer,
  sx,
  ...rest
}: MarkdownProps) => {
  const children = useMemo(() => {
    return parseMarkdown(value, { isInline });
  }, [renderer]);

  if (isInline) {
    return <>{children}</>;
  }

  return (
    <Box
      sx={{
        '& > *:last-child': {
          marginBottom: 0,
        },
        '& > *:first-child': {
          marginTop: 0,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
