import { PropsWithChildren, useMemo } from 'react';

import {
  ApolloCache,
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { NormalizedCacheObjectWithInvalidation } from '@nerdwallet/apollo-cache-policies/dist/entity-store/types';
import merge from 'deepmerge';

import { getCache } from './cache';
import { createLink } from './link';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const typeOf = (value: any) => (typeof value).toLowerCase();

const shallowCompare = (source: any, target: any) => {
  if (typeOf(source) !== typeOf(target)) {
    return false;
  }

  if (typeOf(source) === 'array') {
    return source.length === target.length;
  } else if (typeOf(source) === 'object') {
    return Object.keys(source).every((key) => source[key] === target[key]);
  } else if (typeOf(source) === 'date') {
    return source.getTime() === target.getTime();
  }

  return source === target;
};

const createApolloClient = (
  apolloCache: ApolloCache<NormalizedCacheObject>
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createLink(),
    cache: apolloCache,
    connectToDevTools:
      typeof window !== 'undefined' && process.env.NODE_ENV !== 'production',
  });
};

const initializeApollo = (
  initialState?: NormalizedCacheObject
): ApolloClient<NormalizedCacheObjectWithInvalidation> => {
  let _apolloClient = apolloClient;

  if (!_apolloClient) {
    _apolloClient = createApolloClient(getCache());
  }

  if (initialState) {
    const existingCache = _apolloClient.extract();

    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !shallowCompare(d, s))
        ),
      ],
    });

    _apolloClient.cache.restore(data);
  }

  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
};

export type ApolloServiceProps = {
  initialApolloState?: NormalizedCacheObject;
};

export const ApolloService = ({
  initialApolloState,
  children,
}: PropsWithChildren<ApolloServiceProps>) => {
  const client = useMemo(
    () => initializeApollo(initialApolloState),
    [initialApolloState]
  );

  if (!client) {
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
