import { createContext, useContext } from 'react';

type PageContextProps<T = any> = {
  pageProps: T;
};

export const PageContext = createContext<PageContextProps | undefined>(
  undefined
);

export const usePageContext = <T>() => {
  const context = useContext<PageContextProps<T> | undefined>(PageContext);

  if (!context) {
    throw new Error('usePageContext must be used within a PageContextProvider');
  }

  return context;
};
