import { CssVarsTheme } from '@mui/material';
import merge from 'deepmerge';

import { MuiAccordion } from './MuiAccordion';
import { MuiAlert } from './MuiAlert';
import { MuiAppBar } from './MuiAppBar';
import { MuiButton } from './MuiButton';
import { MuiDivider } from './MuiDivider';
import { MuiLink } from './MuiLink';
import { MuiPaper } from './MuiPaper';
import { MuiTab } from './MuiTab';
import { MuiTableBody } from './MuiTableBody';
import { MuiTableCell } from './MuiTableCell';
import { MuiTableContainer } from './MuiTableContainer';
import { MuiTableHead } from './MuiTableHead';
import { MuiTableRow } from './MuiTableRow';
import { MuiTabs } from './MuiTabs';
import { MuiTextField } from './MuiTextField';
import { MuiToolbar } from './MuiToolbar';
import { MuiTypography } from './MuiTypography';

export const componentsOverrides = (theme: CssVarsTheme) => {
  return merge.all([
    MuiAccordion(theme),
    MuiAlert(theme),
    MuiAppBar(theme),
    MuiButton(theme),
    MuiDivider(theme),
    MuiLink(theme),
    MuiPaper(theme),
    MuiTab(theme),
    MuiTableBody(theme),
    MuiTableCell(theme),
    MuiTableContainer(theme),
    MuiTableHead(theme),
    MuiTableRow(theme),
    MuiTabs(theme),
    MuiTextField(theme),
    MuiToolbar(theme),
    MuiTypography(theme),
  ]);
};
