import { marked } from 'marked';

const label =
  '(?:\\[(?:\\\\.|[^\\[\\]\\\\])*\\]|\\\\.|`[^`]*`|[^\\[\\]\\\\`])*?';
const href = '<(?:\\\\.|[^\\n<>\\\\])+>|[^\\s\\x00-\\x1f]*';

export interface ButtonToken {
  type: 'button';
  raw: string;
  text: string;
  href: string;
  buttonType: string;
}

/**
 * Permet de créer un bouton: [label](lien, buttonType) où buttonType correspond au style du bouton
 * (exemple: principal, secondaire ou lien)
 */
export const buttonExtension: marked.RendererExtension &
  marked.TokenizerExtension = {
  name: 'button',
  level: 'inline',
  start: (src) => {
    return src.match(/\[/)?.index || -1;
  },
  tokenizer: (src) => {
    const rule = new RegExp(
      `^!?\\[(${label})\\]\\(\\s*(${href})\\s*,\\s*(principal|secondaire|lien)\\s*,?\\s*([^)]*)\\)`
    );
    const match = rule.exec(src);
    if (match) {
      return {
        type: 'button',
        raw: match[0],
        text: match[1].trim(),
        href: match[2].trim(),
        buttonType: match[3].trim(),
        message: match[4]?.trim(),
        tokens: marked.Lexer.lexInline(match[1].trim()),
      };
    }

    return undefined;
  },
  renderer: (token) => {
    return `<a href=${token.href}>${token.text}</a>`;
  },
};

// Nok

// [
//   '[label](https://lien)'
//   '[label](https://lien, prout)'
//   '[label](https://lien, prout, test)'
// ].map(
//   [
//     '[label](https://lien)'
//     '[label](https://lien, prout)'
//     '[label](https://lien, prout, test)'
//   ])

// ok

// [
//   '[label](https://lien, principal)',
//   '[label](https://lien, secondaire)',
//   '[label](https://lien, lien)',
//   '[label](#crisp)',
//   '[label](#crisp, principal)',
//   '[label](#crisp, principal, question?)',
//   '[label](#crisp, question?)'
// ].map(tokenizer);
