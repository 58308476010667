import { OverrideComponent, MuiComponents } from './types';

export const MuiTableHead: OverrideComponent = (theme) => {
  const MuiTableHead: MuiComponents['MuiTableHead'] = {
    styleOverrides: {
      root: {
        background: theme.vars.palette.background.default,
        borderBottom: `1px solid ${theme.vars.palette.divider}`,
      },
    },
  };

  return {
    MuiTableHead,
  };
};
