import { OverrideComponent, MuiComponents } from './types';

export const MuiAppBar: OverrideComponent = (theme) => {
  const MuiAppBar: MuiComponents['MuiAppBar'] = {
    defaultProps: {
      color: 'default',
      enableColorOnDark: true,
    },
    styleOverrides: {
      root: {
        boxShadow: `0 0 1.5rem 0 rgba(${theme.vars.palette.primary.mainChannel} / .1),0 0 2px 0 rgba(${theme.vars.palette.primary.mainChannel} / .1)`,
      },
    },
  };

  return {
    MuiAppBar,
  };
};
