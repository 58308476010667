import { PropsWithChildren } from 'react';

import { ErrorBoundary, FallbackRender } from '@sentry/nextjs';

const DefaultFallback: FallbackRender = ({ error, componentStack }) => (
  <>
    <div>You have encountered an error</div>
    <div>{error.toString()}</div>
    <div>{componentStack}</div>
  </>
);

export type SentryFallbackProps = {
  error: Error;
  componentStack?: string;
  eventId?: string;
  resetError(): void;
};

type SentryServiceProps = PropsWithChildren<{
  Fallback?: FallbackRender;
  showDialog?: boolean;
}>;

export const SentryService = ({
  children,
  Fallback,
  showDialog,
}: SentryServiceProps) => {
  return (
    <ErrorBoundary
      fallback={Fallback || DefaultFallback}
      showDialog={showDialog}
    >
      {children}
    </ErrorBoundary>
  );
};
