import { css, Theme } from '@emotion/react';

import color from './color';
import font from './font';

export const globals = (theme: Theme) => css`
  ${color}
  ${font(theme)}

  @media (min-width: 320px) {
    /* Add minimum size breakpoint in chrome inspector */
  }
  @media (min-width: 1500px) {
    /* Add maximum size breakpoint in chrome inspector */
  }

  body {
    overflow-x: hidden;
    width: 100%;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }
`;
