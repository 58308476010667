import { Facebook, Instagram, LinkedIn, X, YouTube } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { RouteLink } from '@scorenco/core';
import type { marked } from 'marked';

import { RendererFn } from '../type';

type SocialProps = {
  icon: JSX.Element;
};

type SocialPropsMap = {
  [key: string]: SocialProps;
};

const SOCIAL_PROPS: SocialPropsMap = {
  facebook: {
    icon: <Facebook />,
  },
  instagram: {
    icon: <Instagram />,
  },
  linkedin: {
    icon: <LinkedIn />,
  },
  twitter: {
    icon: <X />,
  },
  youtube: {
    icon: <YouTube />,
  },
};

export const social: RendererFn<
  marked.Tokens.Link & { icons: string[]; hrefs: string[] }
> = ({ ...linkToken }, { key }) => {
  return (
    <Stack component="span" direction="row" key={key}>
      {linkToken.icons?.map((icon, idx) => {
        const href = linkToken.hrefs[idx];
        const socialIcon = SOCIAL_PROPS[icon];

        if (!socialIcon) {
          return null;
        }

        return (
          <RouteLink key={idx} href={href}>
            <IconButton size="large" color="primary">
              {socialIcon.icon}
            </IconButton>
          </RouteLink>
        );
      })}
    </Stack>
  );
};
