import { PropsWithChildren, useMemo } from 'react';

import { AmplifyServiceContext } from './AmplifyServiceContext';
import { initAuthClient } from './auth-client/factories/amplify-auth-client';
import { useAuthentificationReducer } from './useAuthentificationReducer';

export const AmplifyService = ({ children }: PropsWithChildren) => {
  const reducerState = useAuthentificationReducer();

  const value = useMemo(() => {
    const { state, ...rest } = reducerState;
    return {
      ...state,
      ...rest,
    };
  }, [reducerState]);

  return (
    <AmplifyServiceContext.Provider value={value}>
      {children}
    </AmplifyServiceContext.Provider>
  );
};

AmplifyService.init = initAuthClient;
