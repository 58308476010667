import { useMemo } from 'react';

import { useRouter } from 'next/router';

export const useRouteInfo = (): {
  pathname: string;
  asPath: string;
  query: Record<string, string | undefined>;
} => {
  const router = useRouter();

  const info = useMemo(
    () => ({
      pathname: router.pathname,
      asPath: router.asPath,
      query: router.query as Record<string, string | undefined>,
    }),
    [router.asPath, router.query]
  );

  return info;
};
