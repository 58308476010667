// ------- WARN -------
// Cette configuration doit être chargée via next.config.js. Cela implique que :
// - il faut refaire un yarn dev pour voir ses modifications
// - on ne peut pas utiliser typescript
// - la syntaxe doit être compatible avec node:lts
// --------------------

const SPORTS = [
  // 'baseball',
  'basket',
  'football',
  'hand',
  'hockey-sur-glace',
  'rink-hockey',
  'roller-hockey',
  'rugby',
  'volley',
].join('|');

module.exports = {
  Home: {
    page: '/',
    pathname: '/',
  },
  Healthcheck: {
    page: '/healthcheck',
    pathname: '/healthcheck',
  },
  CognitoCallback: {
    // Point d'entrée après une connexion avec un service tiers (facebook, apple, ...)
    page: '/cognito-callback',
    pathname: '/cognito-callback',
  },
  Connect: {
    // Page pour l'authentification
    page: '/connect',
    pathname: '/connect',
  },

  Sitemap: {
    page: '/sitemap/sitemap.xml',
    pathname: '/sitemap.xml',
  },
  SitemapStatic: {
    page: '/sitemap/sitemap-static.xml',
    pathname: '/sitemap/sitemap-static.xml',
  },
  SitemapCompetitions: {
    page: '/sitemap/sitemap-competitions.xml',
    pathname: '/sitemap/sitemap-competitions.xml',
  },
  SitemapClubs: {
    page: '/sitemap/sitemap-clubs.xml',
    pathname: '/sitemap/sitemap-clubs.xml',
  },
  SitemapLives: {
    page: '/sitemap/sitemap-lives.xml',
    pathname: '/sitemap/sitemap-lives.xml',
  },
  SitemapClubsBySport: {
    page: '/sitemap/:sport/clubs.xml',
    pathname: `/sitemap/:sport(${SPORTS})/clubs.xml`,
  },
  // SitemapClubsFootball: {
  //   page: '/sitemap/sitemap-clubs-football.xml',
  //   pathname: '/sitemap/sitemap-clubs-football.xml',
  // },
  // SitemapClubsHand: {
  //   page: '/sitemap/sitemap-clubs-hand.xml',
  //   pathname: '/sitemap/sitemap-clubs-hand.xml',
  // },
  // SitemapClubsHockeySurGlace: {
  //   page: '/sitemap/sitemap-clubs-hockey-sur-glace.xml',
  //   pathname: '/sitemap/sitemap-clubs-hockey-sur-glace.xml',
  // },
  // SitemapClubsRinkHockey: {
  //   page: '/sitemap/sitemap-clubs-rink-hockey.xml',
  //   pathname: '/sitemap/sitemap-clubs-rink-hockey.xml',
  // },
  // SitemapClubsRollerHockey: {
  //   page: '/sitemap/sitemap-clubs-roller-hockey.xml',
  //   pathname: '/sitemap/sitemap-clubs-roller-hockey.xml',
  // },
  // SitemapClubsRugby: {
  //   page: '/sitemap/sitemap-clubs-rugby.xml',
  //   pathname: '/sitemap/sitemap-clubs-rugby.xml',
  // },
  // SitemapClubsVolley: {
  //   page: '/sitemap/sitemap-clubs-volley.xml',
  //   pathname: '/sitemap/sitemap-clubs-volley.xml',
  // },

  Sport: {
    page: '/sport/:sport',
    pathname: `/:sport(${SPORTS})`,
  },
  Event: {
    page: '/sport/:sport/:event',
    pathname: `/:sport(${SPORTS})/match-:event`,
  },
  EventOverlay: {
    page: '/overlay/:event',
    pathname: `/overlay/:event`,
  },
  Clubs: {
    // Affiche la liste des clubs d'un département pour un sport
    page: '/sport/:sport/clubs',
    pathname: `/:sport(${SPORTS})/clubs`,
  },
  Club: {
    page: '/sport/:sport/clubs/:club',
    pathname: `/:sport(${SPORTS})/clubs/:club`,
  },
  Competitions: {
    page: '/sport/:sport/competitions',
    pathname: `/:sport(${SPORTS})/competitions`,
  },
  Competition: {
    page: '/sport/:sport/competitions/:competition*',
    pathname: `/:sport(${SPORTS})/competitions/:competition*`,
  },
  Team: {
    page: '/sport/:sport/clubs/:club/teams/:team',
    pathname: `/:sport(${SPORTS})/clubs/:club/:team`,
  },
  League: {
    page: '/sport/:sport/leagues/:league',
    locales: {
      'fr-FR': {
        pathname: `/:sport(${SPORTS})/ligues-:league`,
      },
      'de-CH': {
        pathname: `/:sport(${SPORTS})/leagues-:league`,
      },
    },
  },
  Level: {
    page: '/sport/:sport/levels/:level',
    locales: {
      'fr-FR': {
        pathname: `/:sport(${SPORTS})/niveaux-:level`,
      },
      'de-CH': {
        pathname: `/:sport(${SPORTS})/levels-:level`,
      },
    },
  },

  LandingWithParent: {
    page: '/landing/:parentSlug--:landingSlug',
    pathname: '/:parentSlug(clubs|partenaires|fans)/:landingSlug',
  },
  Landing: {
    page: '/landing/:landingSlug',
    pathname: '/:landingSlug',
  },
};
