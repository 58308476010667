import { Translations } from '@scorenco/core';

const NAMESPACE = 'snc-components';

export const T_KEYS = {
  ...Translations.T_KEYS,

  CONFIRM: `${NAMESPACE}:CONFIRM`,
  CANCEL: `${NAMESPACE}:CANCEL`,
  NEXT: `${NAMESPACE}:NEXT`,
  SKIP: `${NAMESPACE}:SKIP`,
  BACK: `${NAMESPACE}:BACK`,
  ERROR_INTERNET_DISCONNECTED: `${NAMESPACE}:ERROR_INTERNET_DISCONNECTED`,

  ERROR_TITLE: `${NAMESPACE}:ERROR_TITLE`,

  TABLE_PAGINATION_STATE: `${NAMESPACE}:TABLE_PAGINATION_STATE`,

  FORM_ERROR_REQUIRED: `${NAMESPACE}:FORM_ERROR_REQUIRED`,
  FORM_ERROR_INVALID_EMAIL: `${NAMESPACE}:FORM_ERROR_INVALID_EMAIL`,

  AUTHENTIFICATION_USERNAME: `${NAMESPACE}:AUTHENTIFICATION_USERNAME`,
  AUTHENTIFICATION_EMAIL: `${NAMESPACE}:AUTHENTIFICATION_EMAIL`,
  AUTHENTIFICATION_PASSWORD: `${NAMESPACE}:AUTHENTIFICATION_PASSWORD`,
  AUTHENTIFICATION_NEW_PASSWORD: `${NAMESPACE}:AUTHENTIFICATION_NEW_PASSWORD`,
  AUTHENTIFICATION_VERIFICATION_CODE: `${NAMESPACE}:AUTHENTIFICATION_VERIFICATION_CODE`,
  AUTHENTIFICATION_VERIFICATION_CODE_TEXT: `${NAMESPACE}:AUTHENTIFICATION_VERIFICATION_CODE_TEXT`,
  AUTHENTIFICATION_RESEND_VERIFICATION_CODE: `${NAMESPACE}:AUTHENTIFICATION_RESEND_VERIFICATION_CODE`,
  AUTHENTIFICATION_SEND_VERIFICATION_CODE_FAILED: `${NAMESPACE}:AUTHENTIFICATION_SEND_VERIFICATION_CODE_FAILED`,
  AUTHENTIFICATION_FORGOT_PASSWORD_CTA: `${NAMESPACE}:AUTHENTIFICATION_FORGOT_PASSWORD_CTA`,
  AUTHENTIFICATION_FORGOT_PASSWORD_SEND_CODE: `${NAMESPACE}:AUTHENTIFICATION_FORGOT_PASSWORD_SEND_CODE`,
  AUTHENTIFICATION_SIGN_IN: `${NAMESPACE}:AUTHENTIFICATION_SIGN_IN`,
  AUTHENTIFICATION_SIGN_IN_CTA: `${NAMESPACE}:AUTHENTIFICATION_SIGN_IN_CTA`,
  AUTHENTIFICATION_SIGN_UP: `${NAMESPACE}:AUTHENTIFICATION_SIGN_UP`,
  AUTHENTIFICATION_SIGN_UP_CTA: `${NAMESPACE}:AUTHENTIFICATION_SIGN_UP_CTA`,
  AUTHENTIFICATION_SIGN_OUT: `${NAMESPACE}:AUTHENTIFICATION_SIGN_OUT`,
  AUTHENTIFICATION_CONFIRM_SIGN_UP_TITLE: `${NAMESPACE}:AUTHENTIFICATION_CONFIRM_SIGN_UP_TITLE`,
  AUTHENTIFICATION_SIGN_IN_TITLE: `${NAMESPACE}:AUTHENTIFICATION_SIGN_IN_TITLE`,
  AUTHENTIFICATION_SIGN_UP_TITLE: `${NAMESPACE}:AUTHENTIFICATION_SIGN_UP_TITLE`,
  AUTHENTIFICATION_FORGOT_PASSWORD_TITLE: `${NAMESPACE}:AUTHENTIFICATION_FORGOT_PASSWORD_TITLE`,
  AUTHENTIFICATION_CONFIRM_FORGOT_PASSWORD_TITLE: `${NAMESPACE}:AUTHENTIFICATION_CONFIRM_FORGOT_PASSWORD_TITLE`,
  AUTHENTIFICATION_APPLE_CONNECT: `${NAMESPACE}:AUTHENTIFICATION_APPLE_CONNECT`,
  AUTHENTIFICATION_FACEBOOK_CONNECT: `${NAMESPACE}:AUTHENTIFICATION_FACEBOOK_CONNECT`,
  AUTHENTIFICATION_ERROR_NOT_AUTHORIZED: `${NAMESPACE}:AUTHENTIFICATION_ERROR_NOT_AUTHORIZED`,
  AUTHENTIFICATION_ERROR_INVALID_PARAMETER: `${NAMESPACE}:AUTHENTIFICATION_ERROR_INVALID_PARAMETER`,
  AUTHENTIFICATION_ERROR_CODE_MISMATCH: `${NAMESPACE}:AUTHENTIFICATION_ERROR_CODE_MISMATCH`,
  AUTHENTIFICATION_ERROR_EXPIRED_CODE: `${NAMESPACE}:AUTHENTIFICATION_ERROR_EXPIRED_CODE`,
  AUTHENTIFICATION_ERROR_USERNAME_EXISTS: `${NAMESPACE}:AUTHENTIFICATION_ERROR_USERNAME_EXISTS`,
  AUTHENTIFICATION_ERROR_PASSWORD_RULES: `${NAMESPACE}:AUTHENTIFICATION_ERROR_PASSWORD_RULES`,
  AUTHENTIFICATION_DELETE_USER: `${NAMESPACE}:AUTHENTIFICATION_DELETE_USER`,

  CLUB_LOGO: `${NAMESPACE}:CLUB_LOGO`,

  COMPETITION_RANKING_TEAMS: `${NAMESPACE}:COMPETITION_RANKING_TEAMS`,
  COMPETITION_RANKING_CELL_PTS: `${NAMESPACE}:COMPETITION_RANKING_CELL_PTS`,
  COMPETITION_RANKING_CELL_PLAYED: `${NAMESPACE}:COMPETITION_RANKING_CELL_PLAYED`,
  COMPETITION_RANKING_CELL_WIN: `${NAMESPACE}:COMPETITION_RANKING_CELL_WIN`,
  COMPETITION_RANKING_CELL_EVEN: `${NAMESPACE}:COMPETITION_RANKING_CELL_EVEN`,
  COMPETITION_RANKING_CELL_LOST: `${NAMESPACE}:COMPETITION_RANKING_CELL_LOST`,
  COMPETITION_RANKING_CELL_GF: `${NAMESPACE}:COMPETITION_RANKING_CELL_GF`,
  COMPETITION_RANKING_CELL_GA: `${NAMESPACE}:COMPETITION_RANKING_CELL_GA`,
  COMPETITION_RANKING_CELL_GD: `${NAMESPACE}:COMPETITION_RANKING_CELL_GD`,
  COMPETITION_RANKING_CELL_RATIO: `${NAMESPACE}:COMPETITION_RANKING_CELL_RATIO`,
  COMPETITION_RANKING_CELL_GNP: `${NAMESPACE}:COMPETITION_RANKING_CELL_GNP`,
  COMPETITION_RANKING_CELL_GP: `${NAMESPACE}:COMPETITION_RANKING_CELL_GP`,
  COMPETITION_RANKING_CELL_SERIE: `${NAMESPACE}:COMPETITION_RANKING_CELL_SERIE`,

  PROFIL_USERNAME: `${NAMESPACE}:PROFIL_USERNAME`,
  PROFIL_EMAIL: `${NAMESPACE}:PROFIL_EMAIL`,
  PROFIL_FIRST_NAME: `${NAMESPACE}:PROFIL_FIRST_NAME`,
  PROFIL_LAST_NAME: `${NAMESPACE}:PROFIL_LAST_NAME`,
  PROFIL_GENRE: `${NAMESPACE}:PROFIL_GENRE`,
  PROFIL_LANG: `${NAMESPACE}:PROFIL_LANG`,

  SEARCH_PLACEHOLDER: `${NAMESPACE}:SEARCH_PLACEHOLDER`,
  SEARCH_NOT_FOUND: `${NAMESPACE}:SEARCH_NOT_FOUND`,

  EVENT_CARD_GIVE_SCORE: `${NAMESPACE}:EVENT_CARD_GIVE_SCORE`,
  EVENT_POSTPONED: `${NAMESPACE}:EVENT_POSTPONED`,
  EVENT_WITHDRAWAL: `${NAMESPACE}:EVENT_WITHDRAWAL`,
  EVENT_LIVE: `${NAMESPACE}:EVENT_LIVE`,
  EVENT_FINISH: `${NAMESPACE}:EVENT_FINISH`,
  EVENT_HALFTIME: `${NAMESPACE}:EVENT_HALFTIME`,

  EVENT_POST_COMMENT_MODIFY: `${NAMESPACE}:EVENT_POST_COMMENT_MODIFY`,
  EVENT_POST_COMMENT_DELETE: `${NAMESPACE}:EVENT_POST_COMMENT_DELETE`,
  EVENT_POST_COMMENT_RETRY: `${NAMESPACE}:EVENT_POST_COMMENT_RETRY`,

  EVENT_POST_ACTION_MODIFY: `${NAMESPACE}:EVENT_POST_ACTION_MODIFY`,
  EVENT_POST_ACTION_DELETE: `${NAMESPACE}:EVENT_POST_ACTION_DELETE`,

  ACTION_VALIDATION_TEXT: `${NAMESPACE}:ACTION_VALIDATION_TEXT`,

  ACTION_FIELD_TEAM_LABEL: `${NAMESPACE}:ACTION_FIELD_TEAM_LABEL`,
  ACTION_FIELD_TEAM_LABEL_CSC: `${NAMESPACE}:ACTION_FIELD_TEAM_LABEL_CSC`,
  ACTION_FIELD_SCORE_LABEL: `${NAMESPACE}:ACTION_FIELD_SCORE_LABEL`,
  ACTION_FIELD_UPDATE_SCORE_LABEL: `${NAMESPACE}:ACTION_FIELD_UPDATE_SCORE_LABEL`,
  ACTION_FIELD_UPDATE_SET_SCORE_LABEL: `${NAMESPACE}:ACTION_FIELD_UPDATE_SET_SCORE_LABEL`,
  ACTION_FIELD_UPDATE_SHOOTOUT_LABEL: `${NAMESPACE}:ACTION_FIELD_UPDATE_SHOOTOUT_LABEL`,
  ACTION_FIELD_REMAINING_MINUTE_LABEL: `${NAMESPACE}:ACTION_FIELD_REMAINING_MINUTE_LABEL`,
  ACTION_FIELD_REMAINING_SECOND_LABEL: `${NAMESPACE}:ACTION_FIELD_REMAINING_SECOND_LABEL`,
  ACTION_FIELD_MINUTE_LABEL: `${NAMESPACE}:ACTION_FIELD_MINUTE_LABEL`,
  ACTION_FIELD_SECOND_LABEL: `${NAMESPACE}:ACTION_FIELD_SECOND_LABEL`,
  ACTION_FIELD_NUMBER_LABEL: `${NAMESPACE}:ACTION_FIELD_NUMBER_LABEL`,
  ACTION_FIELD_LIVE_PLAYER_LABEL: `${NAMESPACE}:ACTION_FIELD_LIVE_PLAYER_LABEL`,
  ACTION_FIELD_LIVE_SCORER_LABEL: `${NAMESPACE}:ACTION_FIELD_LIVE_SCORER_LABEL`,
  ACTION_FIELD_INCOMING_PLAYER_LABEL: `${NAMESPACE}:ACTION_FIELD_INCOMING_PLAYER_LABEL`,
  ACTION_FIELD_OUTGOING_PLAYER_LABEL: `${NAMESPACE}:ACTION_FIELD_OUTGOING_PLAYER_LABEL`,
  ACTION_FIELD_PLAYER_IN_LABEL: `${NAMESPACE}:ACTION_FIELD_PLAYER_IN_LABEL`,
  ACTION_FIELD_PLAYER_OUT_LABEL: `${NAMESPACE}:ACTION_FIELD_PLAYER_OUT_LABEL`,
  ACTION_FIELD_NO_PLAYER: `${NAMESPACE}:ACTION_FIELD_NO_PLAYER`,
  ACTION_SHOOTOUT: `${NAMESPACE}:ACTION_SHOOTOUT`,

  SEARCH_EXPLANATION_TEXT: `${NAMESPACE}:SEARCH_EXPLANATION_TEXT`,

  NO_OPTIONS_TEXT: `${NAMESPACE}:NO_OPTIONS_TEXT`,
  LOADING_TEXT: `${NAMESPACE}:LOADING_TEXT`,

  SUCCESS_DELETE: `${NAMESPACE}:SUCCESS_DELETE`,
  ERROR_MESSAGE: `${NAMESPACE}:ERROR_MESSAGE`,
  DELETE: `${NAMESPACE}:DELETE`,

  TOURNAMENTS_FINALES_LABEL: `${NAMESPACE}:TOURNAMENTS_FINALES_LABEL`,
  TOURNAMENTS_SEMI_FINALS_LABEL: `${NAMESPACE}:TOURNAMENTS_SEMI_FINALS_LABEL`,
  TOURNAMENTS_QUARTER_FINALS_LABEL: `${NAMESPACE}:TOURNAMENTS_QUARTER_FINALS_LABEL`,
  TOURNAMENTS_ROUND_OF_SIXTEEN_LABEL: `${NAMESPACE}:TOURNAMENTS_ROUND_OF_SIXTEEN_LABEL`,
  TODAY: `${NAMESPACE}:TODAY`,
};
