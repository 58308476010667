import { ReactElement } from 'react';

import { I18nService } from '@scorenco/core';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';

import { getConfig } from './config';

export const appEnhancer = (App: (props: AppProps) => ReactElement) => {
  const config = getConfig();

  const AppEnhanced = (props: AppProps) => {
    return (
      <I18nService.Provider>
        <App {...props} />
      </I18nService.Provider>
    );
  };
  const AppEnhancedWithTranslation = appWithTranslation(AppEnhanced, config);

  if (
    ['android', 'ios'].includes(
      process.env.NEXT_PUBLIC_TARGET_PLATFORM || ''
    ) ||
    config.clientOnly
  ) {
    return ({ pageProps, ...props }: AppProps) => (
      <AppEnhancedWithTranslation
        pageProps={{ _nextI18Next: { ns: config.ns }, ...pageProps }}
        {...props}
      />
    );
  }

  return AppEnhancedWithTranslation;
};
