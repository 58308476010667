import { OverrideComponent, MuiComponents } from './types';

export const MuiTypography: OverrideComponent = () => {
  const MuiTypography: MuiComponents['MuiTypography'] = {
    defaultProps: {
      variantMapping: {
        h1: 'p',
        h2: 'p',
        h3: 'p',
        h4: 'p',
        h5: 'p',
        h6: 'p',
        subtitle1: 'p',
        subtitle2: 'p',
        body1: 'p',
        body2: 'p',
        inherit: 'p',
      },
    },
  };

  return {
    MuiTypography,
  };
};
