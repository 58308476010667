/**
 * Ce répertoire contient l'ensemble des clés de traduction utilisées
 * dans le projet core.
 *
 * Lorsque l'on ajoute une clé, il faut bien penser à mettre à jour le fichier dans public/locales/fr.
 * C'est la référence que l'on utilise pour tous les projets.
 */

import { T_KEYS as KEYS } from './keys';

export const T_KEYS = KEYS;
