import { PropsWithChildren, useEffect } from 'react';

import {
  useAmplifyServiceContext,
  useRouteInfo,
  useRouteNavigation,
  useTranslation,
  Locale,
} from '@scorenco/core';

export const DefaultLayoutComponent = ({ children }: PropsWithChildren) => {
  const { locale } = useTranslation();
  const { user } = useAmplifyServiceContext();
  const routeInfo = useRouteInfo();
  const routeNavigation = useRouteNavigation();

  const userLocale = user?.info?.locale as Locale;

  // Si la langue change, on redirige l'utilisateur vers la page correspondante
  // à la nouvelle langue
  useEffect(() => {
    if (userLocale && userLocale !== locale) {
      routeNavigation.replace({ ...routeInfo, locale: userLocale });
    }
  }, [userLocale, JSON.stringify(routeInfo.href)]);

  return <>{children}</>;
};
