import { COLORS } from '../../constants';

import { createColorScheme } from './utils';

export const blueScheme = createColorScheme({
  palette: {
    background: {
      default: COLORS.BLUE_40,
      paper: COLORS.BLUE_30,
    },
    text: {
      primary: COLORS.BLUE_100,
    },
    primary: {
      dark: COLORS.BLUE_70,
      main: COLORS.BLUE_99,
      light: COLORS.BLUE_99,
      contrastText: COLORS.BLUE_40,
    },
    logo: {
      score_co: COLORS.BLUE_100,
    },
    divider: COLORS.BLUE_60,
  },
});
