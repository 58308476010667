import { OverrideComponent, MuiComponents } from './types';

export const MuiAlert: OverrideComponent = (theme) => {
  const MuiAlert: MuiComponents['MuiAlert'] = {
    styleOverrides: {
      root: {
        border: '1px solid transparent',
      },
      standardError: {
        borderColor: `${theme.vars.palette.error.dark}66`,
      },
      standardWarning: {
        borderColor: `${theme.vars.palette.warning.dark}66`,
      },
      standardInfo: {
        borderColor: `${theme.vars.palette.info.dark}66`,
      },
      standardSuccess: {
        borderColor: `${theme.vars.palette.success.dark}66`,
      },
      filledError: {
        backgroundColor: theme.vars.palette.error.main,
        color: theme.vars.palette.error.contrastText,
      },
      filledWarning: {
        backgroundColor: theme.vars.palette.warning.main,
        color: theme.vars.palette.warning.contrastText,
      },
      filledInfo: {
        backgroundColor: theme.vars.palette.info.main,
        color: theme.vars.palette.info.contrastText,
      },
      filledSuccess: {
        backgroundColor: theme.vars.palette.success.main,
        color: theme.vars.palette.success.contrastText,
      },
    },
  };

  return {
    MuiAlert,
  };
};
