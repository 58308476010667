const config = {
  // CMP version
  version: 2,
  logger:
    process.env.NODE_ENV === 'development'
      ? console.info
      : () => {
          // Do nothing
        },
};

export const show = (options: { jumpAt?: 'banner' | 'privacy' } = {}) => {
  if (!window.__tcfapi) {
    throw new Error('CMP is not initialized');
  }

  window.__tcfapi('show', config.version, config.logger, options);
};
