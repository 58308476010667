import { ALL_SPORTS } from '../constants';

const NAMESPACE = 'snc-core';

const SPORT_KEYS = ALL_SPORTS.reduce(
  (acc, sport) => ({
    ...acc,
    [`SPORTS_${sport}`]: `${NAMESPACE}:SPORTS_${sport}`,
  }),
  {} as Record<`SPORTS_${(typeof ALL_SPORTS)[0]}`, string>
);

export const T_KEYS = {
  PICTURE_TITLE: `${NAMESPACE}:PICTURE_TITLE`,
  PICTURE_CANCEL: `${NAMESPACE}:PICTURE_CANCEL`,
  PICTURE_TAKE_PICTURE: `${NAMESPACE}:PICTURE_TAKE_PICTURE`,
  PICTURE_FROM_PHOTOS: `${NAMESPACE}:PICTURE_FROM_PHOTOS`,

  ...SPORT_KEYS,
};
