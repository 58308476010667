import { Edit } from '@mui/icons-material';
import { SpeedDialAction } from '@mui/material';

type DebugModeProps = {
  type: string;
  strapiId?: string;
};

/**
 * Lien vers la page strapi correspondante
 */
export const DebugModeStrapiLink = ({
  type,
  strapiId = '',
  ...rest
}: DebugModeProps) => {
  return (
    <SpeedDialAction
      icon={<Edit />}
      tooltipTitle="Editer sur strapi"
      onClick={() => {
        window.open(
          [
            `${process.env.NEXT_PUBLIC_STRAPI_ADMIN}/content-manager/${
              strapiId ? 'collectionType' : 'singleType'
            }/api::${type}.${type}`,
            strapiId,
          ]
            .filter(Boolean)
            .join('/'),
          '_blank'
        );
      }}
      {...rest}
    />
  );
};
