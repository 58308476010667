import {
  createContext,
  useEffect,
  useContext,
  useMemo,
  useState,
  ElementType,
  PropsWithChildren,
  useCallback,
} from 'react';

import {
  getItemFromStorage,
  setItemToStorage,
  useRouteInfo,
} from '@scorenco/core';

const NavigationContext = createContext<NavigationContextProps | undefined>(
  undefined
);

export const useNavigationContext = (): NavigationContextProps => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigationContext must be inside an NavigationContext');
  }
  return context;
};

type NavigationContextProps = {
  noBurgerDrawer: boolean;
  drawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  AppBarActions?: ElementType;
  message?: string;
  showMessage: (message?: string) => void;
  hideMessage: () => void;
};

type NavigationProviderProps = PropsWithChildren<{
  noBurgerDrawer?: boolean;
  AppBarActions?: ElementType;
}>;

export const NavigationProvider = ({
  children,
  noBurgerDrawer: noBurgerDrawerProp = false,
  AppBarActions,
}: NavigationProviderProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [noBurgerDrawer, setNoDrawerMenu] = useState(noBurgerDrawerProp);
  const [message, setMessage] = useState<string | undefined>();
  const routeInfo = useRouteInfo();

  const showMessage = useCallback((message?: string) => {
    if (!message) {
      setMessage(undefined);
    } else if (!getItemFromStorage(message)) {
      setMessage(message);
    }
  }, []);

  const hideMessage = useCallback(() => {
    if (message) {
      setItemToStorage(message, 'true');
    }
    setMessage(undefined);
  }, [message]);

  const context = useMemo(
    () => ({
      drawerOpen,
      setDrawerOpen,
      noBurgerDrawer,
      setNoDrawerMenu,
      AppBarActions,
      message,
      showMessage,
      hideMessage,
    }),
    [
      drawerOpen,
      setDrawerOpen,
      noBurgerDrawer,
      setNoDrawerMenu,
      AppBarActions,
      message,
      showMessage,
    ]
  );

  useEffect(() => {
    setNoDrawerMenu(noBurgerDrawerProp);
  }, [noBurgerDrawerProp]);

  // Ferme le menu quand la route change
  useEffect(() => {
    setDrawerOpen(false);
  }, [routeInfo?.asPath]);

  return (
    <NavigationContext.Provider value={context}>
      {children}
    </NavigationContext.Provider>
  );
};
