import { css } from '@emotion/react';

import { vars } from '../vars';

const hexToRgb = (hex: string) => {
  const rgbHex = hex.match(/\w{1,2}/g);
  if (!rgbHex) {
    return hex;
  }

  return [
    parseInt(rgbHex[0], 16),
    parseInt(rgbHex[1], 16),
    parseInt(rgbHex[2], 16),
  ].join(', ');
};

const primitiveColor = {
  black: '#000',

  white: '#fff',

  primary100: '#e6fff4',
  primary200: '#bef5dd',
  primary300: '#86ecbf',
  primary400: '#45e29d',
  primary500: '#17db84',
  primary600: '#00be77',

  secondary100: '#f3f5f9',
  secondary200: '#d3e4f3',
  secondary300: '#6897cd',
  secondary400: '#225e9f',
  secondary500: '#00447f',
  secondary600: '#003563',
  secondary700: '#00274a',
  secondaryRGB: hexToRgb('#00274a'),
};

const color = {
  primary: {
    main: '#17db84',
    shadow: 'rgba(23,	219,	132, 0.3)',
  },
  shade2: '#00315c',
  shade3: '#225e9f',
  shade6: '#f3f5f9',
  shade7: '#fff',

  gold: '#FED385',
};

export default css`
  :root {
    ${vars.color.primary.shadow}: ${color.primary
      .shadow}; // rgba(23,	219,	132, 0.3)

    ${vars.color.shade2}: ${color.shade2}; // #00315c
    ${vars.color.shade3}: ${color.shade3}; // #225e9f
    ${vars.color.shade6}: ${color.shade6}; // #f3f5f9
    ${vars.color.shade7}: ${color.shade7}; // #fff

    ${vars.color.gold}: ${color.gold}; // #FED385

    ${vars.color.primary100}: ${primitiveColor.primary100}; // #e6fff4
    ${vars.color.primary200}: ${primitiveColor.primary200}; // #bef5dd
    ${vars.color.primary300}: ${primitiveColor.primary300}; // #86ecbf
    ${vars.color.primary500}: ${primitiveColor.primary500}; // #17db84
    ${vars.color.primary600}: ${primitiveColor.primary600}; // #00be77

    ${vars.color.secondary100}: ${primitiveColor.secondary100}; // #f3f5f9
    ${vars.color.secondary200}: ${primitiveColor.secondary200}; // #d3e4f3
    ${vars.color.secondary300}: ${primitiveColor.secondary300}; // #6897cd
    ${vars.color.secondary500}: ${primitiveColor.secondary500}; // #00447f
  }
`;
