/**
 * Contient la liste des couleurs Score'n'co
 *
 * Les couleurs de thèmes sont déclinés sous différentes luminosité:
 * - 0: le plus foncé
 * - 40: la couleur d'origine
 * - 100: le plus clair
 */

const GREEN = {
  GREEN_0: '#002F1E',
  GREEN_10: '#005E3C',
  GREEN_20: '#008E5A',
  GREEN_30: '#00be77',
  GREEN_40: '#17db84',
  GREEN_50: '#45e29d',
  GREEN_60: '#5FEEAE',
  GREEN_70: '#87F2C2',
  GREEN_80: '#AFF7D6',
  GREEN_90: '#D7FBEB',
  GREEN_99: '#EEFDF6',
  GREEN_100: '#fff',
};

const BLUE = {
  BLUE_0: '#000000',
  BLUE_10: '#041120',
  BLUE_20: '#082240',
  // Exemple: Couleur du texte
  BLUE_30: '#0C335F',
  BLUE_40: '#10447F',
  BLUE_50: '#1661B6',
  BLUE_60: '#3688E6',
  BLUE_70: '#5C9EEB',
  BLUE_80: '#92BFF2',
  // Exemple: Border
  BLUE_90: '#d2e0ef',
  // Exemple: Background
  BLUE_99: '#F3F5F9',
  BLUE_100: '#fff',
};

const YELLOW = {
  YELLOW_0: '#C07D02',
  YELLOW_10: '#E69602',
  YELLOW_20: '#FDB839',
  YELLOW_30: '#FDC65F',
  YELLOW_40: '#FED385',
  YELLOW_50: '#FEDA97',
  YELLOW_70: '#FEE9C2',
  YELLOW_90: '#FFF2DB',
  YELLOW_99: '#FFFBF3',
  YELLOW_100: '#FFFFFF',
};

const RED = {
  RED_0: '#7A1029',
  RED_10: '#981433',
  RED_20: '#B7183D',
  RED_30: '#D51C47',
  RED_40: '#E42F59',
  RED_50: '#E84D71',
  RED_60: '#EC6B89',
  RED_70: '#F088A0',
  RED_80: '#F3A6B8',
  RED_90: '#F7C4D0',
  RED_99: '#FBE1E7',
  RED_100: '#FFFFFF',
};

export const COLORS = {
  ...GREEN,
  ...BLUE,
  ...YELLOW,
  ...RED,
};
