import { PropsWithChildren } from 'react';

import { GoogleAnalytics } from 'nextjs-google-analytics';

export const NextGoogleAnalyticsService = ({ children }: PropsWithChildren) => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) {
    return <>{children}</>;
  }

  return (
    <>
      <GoogleAnalytics
        trackPageViews={{ ignoreHashChange: true }}
        gaMeasurementId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}
      />
      {children}
    </>
  );
};
