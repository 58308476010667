import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import type { marked } from 'marked';

import type { RendererFn } from '../type';

export const table: RendererFn<marked.Tokens.Table> = (
  { header, rows },
  { key, parse, renderer }
) => {
  return (
    <TableContainer key={key}>
      <Table>
        <TableHead>
          <TableRow>
            {header.map((cell, idx) => (
              <TableCell key={idx}>{parse(cell.tokens, renderer)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {
            return (
              <TableRow key={idx}>
                {row.map((cell, index) => {
                  return (
                    <TableCell key={index} component="th" scope="row">
                      {parse(cell.tokens, renderer)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
