import {
  getItemFromStorage,
  setItemToStorage,
  removeItemFromStorage,
} from './localStorage';

const getToken = () => getItemFromStorage('token');

const setToken = (value?: string) => {
  setItemToStorage('token', value || '');
};

const getIsSuperUser = () => getItemFromStorage('superuser') === 'true';

const setIsSuperUser = (value = false) => {
  setItemToStorage('superuser', value ? 'true' : 'false');
};

const getLocale = () => getItemFromStorage('locale');

const setLocale = (value: string) => {
  setItemToStorage('locale', value);
};

const reset = () => {
  removeItemFromStorage('token');
  removeItemFromStorage('superuser');
};

export const userSession = {
  getToken,
  setToken,
  getIsSuperUser,
  setIsSuperUser,
  getLocale,
  setLocale,
  reset,
};
