import { setContext } from '@apollo/client/link/context';

import { userSession } from '../../../helpers';

export const authLink = setContext((operation, prevContext = {}) => {
  const token = userSession.getToken();
  const isSuperUser = userSession.getIsSuperUser();
  const locale = userSession.getLocale();

  const context = {
    ...prevContext,
    headers: {
      ...prevContext.headers,
      'x-hasura-role': 'anonymous',
      'x-hasura-locale': locale,
    },
  };

  if (prevContext.role !== 'anonymous' && token) {
    context.headers.authorization = token;
    context.headers['x-hasura-role'] = prevContext.role || 'user';

    if (prevContext.role === 'club' && !prevContext.forceRole && isSuperUser) {
      context.headers['x-hasura-role'] = 'superuser';
    }
  }

  return context;
});
