import { RendererFn } from '../type';

import { blockquote } from './blockquote';
import { br } from './br';
import { button } from './button';
import { code } from './code';
import { codespan } from './codespan';
import { del } from './del';
import { em } from './em';
import { escape } from './escape';
import { heading } from './heading';
import { hr } from './hr';
import { html } from './html';
import { image } from './image';
import { link } from './link';
import { list } from './list';
import { listItem } from './listItem';
import { paragraph } from './paragraph';
import { social } from './social';
import { space } from './space';
import { strong } from './strong';
import { table } from './table';
import { text } from './text';

export const defaultRenderer: Record<string, RendererFn<any>> = {
  blockquote,
  br,
  button,
  code,
  codespan,
  del,
  em,
  escape,
  heading,
  hr,
  html,
  image,
  link,
  list,
  listItem,
  paragraph,
  space,
  strong,
  table,
  text,
  social,
};
