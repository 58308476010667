import type { marked } from 'marked';

import type { RendererFn } from '../type';

export const del: RendererFn<marked.Tokens.Del> = (
  { tokens },
  { parse, key }
) => {
  return <del key={key}>{parse(tokens)}</del>;
};
