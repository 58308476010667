import { ComponentType } from 'react';

import { EmotionCache } from '@emotion/utils';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { createEmotionCache } from './createEmotionCache';
import { GlobalThemeProvider } from './GlobalThemeProvider';

export const appWithTheme: (App: ComponentType<any>) => ComponentType<any> = (
  App
) => {
  const AppEnhancer = ({
    emotionCache = createEmotionCache(),
    ...rest
  }: {
    emotionCache?: EmotionCache;
  }) => (
    <GlobalThemeProvider cache={emotionCache}>
      <App {...rest} />
    </GlobalThemeProvider>
  );

  return hoistNonReactStatics(AppEnhancer, App);
};
