import { OverrideComponent, MuiComponents } from './types';

export const MuiTextField: OverrideComponent = () => {
  const MuiTextField: MuiComponents['MuiTextField'] = {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: {
        // '& label': {
        //   color: theme.vars.palette.grey[200],
        //   '.Mui-focused': {
        //     color: 'green',
        //   },
        // },
        // '& label.Mui-focused': {
        //   color: 'green',
        // },
        // '& .MuiInput-underline:after': {
        //   borderBottomColor: 'green',
        // },
        // '& .MuiOutlinedInput-root': {
        //   '& fieldset': {
        //     borderColor: theme.vars.palette.grey[200],
        //   },
        //   '&:hover fieldset': {
        //     borderColor: 'yellow',
        //   },
        //   '&.Mui-focused fieldset': {
        //     borderColor: 'green',
        //   },
        // },
      },
    },
  };

  return {
    MuiTextField,
  };
};
